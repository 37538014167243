import PropTypes from 'prop-types'
import { applyMiddleware, middlewares } from '../middleware'
import { initialState, rootReducer } from '../reducers'
import React, { createContext, useReducer, useRef, useContext, useEffect } from 'react'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState)

  useEffect(() => {
    currentStateRef.current = state
  }, [state])

  const currentStateRef = useRef(initialState)

  const getState = () => currentStateRef.current
  const enhancedDispatch = applyMiddleware(dispatch, getState, middlewares)

  return (
    <AppContext.Provider value={{ state, dispatch: enhancedDispatch }}>
      {children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
}
// Hook that components can use to access the context
export const useAppContext = () => useContext(AppContext)
