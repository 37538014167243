import { useContext, useCallback } from 'react'
import { AppContext } from '../context/AppProvider'
import { collectionsLodingFetch, collectionsSuccessFetch, collectionsFailedFetch } from '../actions/collectiosActions'

export const useCollections = () => {
  const { state, dispatch } = useContext(AppContext)

  const apiUrl = 'https://api.primusvest.ro/v1/collections'

  const fetchCollections = useCallback(async () => {
    dispatch(collectionsLodingFetch())
    try {
      const response = await fetch(apiUrl)
      const data = await response.json()
      dispatch(collectionsSuccessFetch(data))
    } catch (error) {
      console.error('Failed to fetch brands:', error)
      dispatch(collectionsFailedFetch(error))
    }
  }, []) // Dependencies array ensures fetchBrands is stable

  return { state, fetchCollections }
}
