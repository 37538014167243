import * as React from 'react'
import { useTheme } from '@mui/system'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import useMediaQuery from '@mui/material/useMediaQuery'
import './HeroGrid.css'
import Skeleton from '@mui/material/Skeleton'

import { useAppContext } from '../../context/AppProvider' // This is the new import
const HeroGrid = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))
  const { state } = useAppContext()
  const skeletonItemsRange = (start, end) => {
    return Array.from({ length: end - start }, (v, k) => k + start)
  }

  if (state.settings.loading) {
    return (
      <ImageList sx={{ width: '1700px', bgcolor: theme.palette.primary.main, padding: '3px' }} cols={matches ? 1 : 2} gap={3} /* Adjust '3' to your preferred column count for larger screens */>
        {skeletonItemsRange(0, 4).map((_, index) => (
          <ImageListItem key={index} sx={{ overflow: 'hidden' }}>
              <Skeleton variant="rectangular" width={2100} height={1180} animation="wave" />
          </ImageListItem>
        ))}
      </ImageList>
    )
  }

  return (
        <ImageList
          sx={{ width: '1500px', bgcolor: theme.palette.primary.main, border: '2px solid', borderColor: theme.palette.primary.main }}
          cols={matches ? 1 : 2}
          gap={2}
        >
        {state.settings.data.map((item) => (
          <ImageListItem key={item.img} sx={{ overflow: 'hidden' }}>
            <img
              className='hero-img'
              src={`${item.img}?w=248&fit=crop&auto=format`}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="eager"
            />
          </ImageListItem>
        ))}
      </ImageList>

  )
}

export default HeroGrid
