import React, { useState } from 'react'
import { theme } from '../../theme.js'
import { Box, Stack, TextField, Button, InputAdornment, IconButton } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useUser } from '../../hooks/useUser.js'
import { parsePassword } from '../../utils/validation'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const PasswordResetForm = () => {
  const { handleUpdateUserProfile } = useUser()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    reset
  } = useForm({
    defaultValues: {
      password: '',
      repeat_password: ''
    }
  })

  const [updateProfileMessage, setUpdateProfileMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const watchedFields = watch()

  const onSubmit = async (data) => {
    // Before attempting to submit, check if passwords match
    if (data.password !== data.repeat_password) {
      setError('repeat_password', {
        type: 'manual',
        message: 'Passwords must match.'
      })
      return // Prevent form submission if passwords do not match
    }

    setLoading(true) // Start loading
    try {
      await handleUpdateUserProfile({ password_hashed: data.password }) // Ensure handleUpdateUserProfile is adapted to handle password update
      setUpdateProfileMessage('Profile updated successfully.')
      reset()
    } catch (error) {
      setUpdateProfileMessage(`Update profile failed: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ width: '100%', marginTop: '30px', padding: '20px', minHeight: { md: '50vh' } }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <TextField
            label="Parola nouă"
            {...register('password', {
              required: 'Câmp obligatoriu',
              validate: parsePassword // Adjust parsePassword to return a boolean or error message
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
            type={showPassword ? 'text' : 'password'}
            size='small'
            sx={{ bgcolor: theme.palette.primary.main, borderRadius: '5px' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            label="Repetă parola"
            {...register('repeat_password', {
              required: 'Câmp obligatoriu',
              validate: value =>
                value === watchedFields.password || 'Passwords do not match'
            })}
            error={!!errors.repeat_password}
            helperText={errors.repeat_password?.message}
            type={showPassword ? 'text' : 'password'}
            size='small'
            sx={{ bgcolor: theme.palette.primary.main, borderRadius: '5px' }}
          />
          <Button
            type="submit"
            variant="outlined"
            disabled={loading}
            size='small'
            color="accent"
            sx={{ borderRadius: '21px', bgcolor: theme.palette.primary.main }}
          >
            Salvează
          </Button>
        </Stack>
      </form>
      {updateProfileMessage && <Box sx={{ mt: 2 }}>{updateProfileMessage}</Box>}
    </Box>
  )
}

export default PasswordResetForm
