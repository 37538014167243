export const SEARCH_INITIATE = 'SEARCH_INITIATE'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILURE = 'SEARCH_FAILURE'
export const SEARCH_CLOSE = 'SEARCH_CLOSE'
export const SEARCH_OPEN = 'SEARCH_OPEN'
export const SEARCH_INPUT = 'SEARCH_INPUT'

export const searchInitiate = (searchTerm) => ({
  type: SEARCH_INITIATE,
  payload: searchTerm
})

export const searchSuccess = (results) => ({
  type: SEARCH_SUCCESS,
  payload: results
})

export const searchFailure = (error) => ({
  type: SEARCH_FAILURE,
  payload: error
})

export const searchClose = () => ({
  type: SEARCH_CLOSE
})

export const searchOpen = () => ({
  type: SEARCH_OPEN
})

export const setInput = (input) => ({
  type: SEARCH_INPUT,
  payload: input
})
