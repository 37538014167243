export const SETTINGS_FETCH = 'SETTINGS_FETCH'
export const SETTINGS_FAILED = 'SETTINGS_FAILED'
export const SETTINGS_LOADING = 'SETTINGS_LOADING'

export const settingsSuccessFetch = (results) => ({
  type: SETTINGS_FETCH,
  payload: results
})

export const settingsFailedFetch = (error) => ({
  type: SETTINGS_FAILED,
  payload: error
})
export const settingsLodingFetch = () => ({
  type: SETTINGS_LOADING
})
