import React, { useState, useEffect, useCallback } from 'react'
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Slider, Typography, Skeleton, Button, Stack } from '@mui/material'
import { theme } from '../../theme'
import styled from '@emotion/styled'
import ChecklistIcon from '@mui/icons-material/Checklist'
import { useAppContext } from '../../context/AppProvider'
import PropTypes from 'prop-types'

const Filter = ({ filtersData, initialFilters, loading, applyFilters, closeFilter }) => {
  const { state } = useAppContext()

  const result = state.collections.brands?.data.reduce((acc, item) => {
    if (item.maxPrice != null && typeof item.maxPrice === 'number' && item.maxPrice > acc.maxPrice) {
      acc.maxPrice = Math.ceil(item.maxPrice)
    }
    if (item.minPrice != null && typeof item.minPrice === 'number' && item.minPrice < acc.minPrice) {
      acc.minPrice = Math.floor(item.minPrice)
    }
    acc.totalCount += item.totalCount || 0 // Make sure totalCount is a number
    return acc
  }, { maxPrice: -Infinity, totalCount: 0, minPrice: Infinity }) // Initial value

  const [localFilters, setLocalFilters] = useState(initialFilters)

  const [priceRange, setPriceRange] = useState(initialFilters.price ? initialFilters.price.split('-').map(p => parseInt(p, 10)) : [result.minPrice, result.maxPrice]) // Initial price range
  const [priceOrder, setPriceOrder] = useState(initialFilters.sort ? initialFilters.sort.split(',')[1] : null)

  const isValidPriceRange = (priceRangeString) => {
    const parts = priceRangeString.split('-')
    if (parts.length === 2 && !parts.some(part => isNaN(parseInt(part, 10)))) {
      return true
    }
    return false
  }

  useEffect(() => {
    setLocalFilters(initialFilters)

    if (initialFilters.sort) {
      const sortOrder = initialFilters.sort.split(',')[1]
      setPriceOrder(sortOrder)
    } else {
      setPriceOrder(null) // Reset the sort order if no sort is specified in initialFilters
    }
    if (result.minPrice !== Infinity && result.maxPrice !== -Infinity && initialFilters?.price) {
      if (initialFilters.price && isValidPriceRange(initialFilters.price)) {
        setPriceRange(initialFilters.price.split('-').map(p => parseInt(p, 10)))
      } else {
        setPriceRange([result.minPrice, result.maxPrice])
      }
    }
  }, [result.minPrice, result.maxPrice, initialFilters])

  useEffect(() => {
    setLocalFilters(initialFilters)
  }, [initialFilters])

  const handleChange = useCallback((type, value, isChecked) => {
    setLocalFilters(prev => ({
      ...prev,
      [type]: isChecked ? [...prev[type], value] : prev[type].filter(item => item !== value)
    }))
  }, [])

  const onApplyFilters = () => {
    applyFilters(localFilters)
    if (closeFilter !== undefined) {
      closeFilter(false)
    }
  }

  const handlePriceChange = (event, newValue) => {
    setLocalFilters(prev => ({ ...prev, price: newValue.join('-') }))
    setPriceRange(newValue)
  }

  const handlePriceOrderChange = (event) => {
    const newPriceOrder = event.target.name
    setPriceOrder((prevPriceOrder) => {
      const porder = prevPriceOrder === newPriceOrder ? null : newPriceOrder
      setLocalFilters((prevFilters) => {
        const newFilters = { ...prevFilters }

        if (porder) {
          newFilters.sort = `price,${porder}`
        } else {
          delete newFilters.sort
        }
        return newFilters
      })

      return porder
    })
  }

  const FilterName = React.useMemo(() => styled(Typography)(({ theme }) => ({
    fontSize: '15px',
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingBottom: '15px'

  })), [])

  const StyledFilterBox = React.useMemo(() => styled(Box)(({ theme }) => ({
    padding: '20px 0'

  })), [])

  const ScrollableInsideBox = React.useMemo(() => styled(Box)(({ theme }) => ({

    overflowY: 'auto', // Make the container scrollable if content overflows
    maxHeight: '205px', // Set a max height for the container
    overflowX: 'hidden', // Hide horizontal scrollbar
    scrollbarWidth: 'thin'
  })), []
  )

  const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    height: '30px'
  }))

  return (
    <Box
     bgcolor={theme.palette.primary.main}
     flex={1} p="20px 30px"
     border="solid 1px"
     borderColor={{ xs: theme.palette.primary.main, md: theme.palette.borderColor.main }}
     borderRadius="4px"
     >
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
          <Typography variant='span' fontSize="18px" fontWeight={600}>Filtre</Typography>
          <Button
            variant="outlined"
            size='small'
            onClick={onApplyFilters}
            endIcon={<ChecklistIcon />}
            color="accent"
            sx={{ color: 'accent', borderRadius: '10px', height: 'auto' }}
          >
          Aplică
          </Button>
        </Stack>
        <Divider orientation='horizontal'aria-hidden="true" color={theme.palette.fontColor.main} />
        { loading
          ? (
                <Box sx={{ minWidth: '250px' }}>
                  <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                </Box>
            )
          : (
        <>
        <StyledFilterBox>
            <FilterName>Preț</FilterName>

            <Slider
                id='priceFilter'
                value={priceRange}
                onChange={handlePriceChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value}`}
                min={result.minPrice}
                max={result.maxPrice}
                size="small"
                sx={{ color: theme.palette.fontColor.medium }}

      />
      <ScrollableInsideBox>
      <FormGroup>
                <FormControlLabel
                  control={<Checkbox id='filter-asc' size='small' color="secondary" checked={priceOrder === 'ASC'} onChange={handlePriceOrderChange} name="ASC" />}
                  label={<Typography style={{ fontSize: '14px', fontWeight: 500 }}>Preț crescător</Typography>}
                />
                <FormControlLabel
                  control={<Checkbox id='filter-desc' size='small' color="secondary" checked={priceOrder === 'DESC'} onChange={handlePriceOrderChange} name="DESC" />}
                  label={<Typography style={{ fontSize: '14px', fontWeight: 500 }}>Preț descrescător</Typography>}
                />
              </FormGroup>
      </ScrollableInsideBox>

        </StyledFilterBox>
      { filtersData?.data?.categories &&
        <StyledFilterBox>
        <FilterName>Categorie</FilterName>
        <ScrollableInsideBox>
        <FormGroup>
            {filtersData?.data?.categories && filtersData.data.categories.map((item, index) => (
                <StyledFormControlLabel key={index}
                control={<Checkbox id={item.link} size='small' color="secondary"
                checked={localFilters.categories.includes(item.link)} // Ensure this reflects the current filter state
                onChange={(e) => handleChange('categories', item.link, e.target.checked)}
                />}
                label={<Typography style={{ fontSize: '14px', fontWeight: 500 }}>{item.name}</Typography>}
                />
            ))}

        </FormGroup>
        </ScrollableInsideBox>

        </StyledFilterBox> }
        { filtersData?.data?.brands &&
        <StyledFilterBox>
            <FilterName>Brand</FilterName>
            <ScrollableInsideBox>
            <FormGroup >
            {filtersData?.data?.brands && filtersData.data.brands.sort((a, b) => {
              if (a.name === 'altele') return 1
              if (b.name === 'altele') return -1
              return a.name.localeCompare(b.name)
            }).map((item, index) => (
                <StyledFormControlLabel key={index}
                control={<Checkbox id={item.link} size='small' color="secondary"
                checked={localFilters.brands.includes(item.link)} // Ensure this reflects the current filter state
                onChange={(e) => handleChange('brands', item.link, e.target.checked)}
                />}
                label={<Typography style={{ fontSize: '14px', fontWeight: 500, textTransform: 'capitalize' }}>{item.name}</Typography>}
                />
            ))}

        </FormGroup>
        </ScrollableInsideBox>
        </StyledFilterBox> }
        </>)}
    </Box>

  )
}
Filter.propTypes = {
  initialFilters: PropTypes.object,
  closeFilter: PropTypes.func,
  applyFilters: PropTypes.func,
  searchParams: PropTypes.object,
  filtersData: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    price: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.string),
    brands: PropTypes.arrayOf(PropTypes.string)
  })
}

export default Filter
