import React, { useState } from 'react'
import './ImageMagnifier.css'
import PropTypes from 'prop-types'
import { ImageLoader } from '../ImageLoader'

const ImageMagnifier = ({ imageUrl }) => {
  // useState is used to manage component state
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [showMagnifier, setShowMagnifier] = useState(false)
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })

  // Function to handle mouse hovers over the image
  const handleMouseHover = (e) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect()

    // Calculate the position (x, y) as a percentage based on cursor location
    const x = ((e.pageX - left) / width) * 100
    const y = ((e.pageY - top) / height) * 100
    setPosition({ x, y })

    // Update cursorPosition to store the current mouse cursor coordinates relative to the image
    setCursorPosition({ x: e.pageX - left, y: e.pageY - top })
  }
  return (
    <div className='img-magnifier-container'
        onMouseEnter={ () => setShowMagnifier(true)}
        onMouseLeave={ () => setShowMagnifier(false)}
        onMouseMove={ handleMouseHover }
        >
        {/* Display the main image */}
        <ImageLoader className='magnifier-img' src={imageUrl} alt='Product Image' loading="eager"/>
       {showMagnifier && <div
        style={{
          position: 'absolute',
          // Position the magnifier near the cursor
          left: `${cursorPosition.x - 100}px`,
          top: `${cursorPosition.y - 100}px`,
          // Make sure the magnifier doesn't interfere with mouse events
          pointerEvents: 'none'
        }}>
            <div className='magnifier-image'
            style={{ scale: 2, backgroundImage: `url(${imageUrl})`, backgroundPosition: `${position.x}% ${position.y}%` }}
            />
        </div>}
        </div>
  )
}

ImageMagnifier.propTypes = {
  imageUrl: PropTypes.string.isRequired
}
export default ImageMagnifier
