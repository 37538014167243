import { useCallback } from 'react'

export const useFilters = () => {
  const apiUrl = 'https://api.primusvest.ro/v1/filters'

  const fetchFilters = useCallback(async (queryParams) => {
    let url = `${apiUrl}`
    let first = true
    for (const queryParam in queryParams) {
      url += first ? `?${queryParams[queryParam]}` : `&${queryParams[queryParam]}`
      first = false
    }
    try {
      const response = await fetch(`${url}`)
      const data = await response.json()
      return { data: data.data, error: null }
    } catch (error) {
      console.error('Failed to fetch filters:', error)
      return { data: null, error }
    }
  }, [])

  return { fetchFilters }
}
