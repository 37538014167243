import { SEARCH_INITIATE, SEARCH_SUCCESS, SEARCH_FAILURE, SEARCH_CLOSE, SEARCH_OPEN, SEARCH_INPUT } from '../actions/searchActions'

const initialState = { loading: false, open: false, input: '', results: [], total: 0, error: null }

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_INITIATE:
      return { ...state, loading: true, open: true, input: action.payload }
    case SEARCH_SUCCESS:
      return { ...state, loading: false, open: true, results: action.payload.data, total: action.payload.total }
    case SEARCH_FAILURE:
      return { ...state, loading: false, open: false, error: action.payload }
    case SEARCH_CLOSE:
      return { ...state, open: false }
    case SEARCH_OPEN:
      return { ...state, open: true }
    case SEARCH_INPUT:
      return { ...state, input: action.payload }
    default:
      return state
  }
}

// export default searchReducer
