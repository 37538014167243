export const ITEMS_FETCH = 'ITEMS_FETCH'
export const ITEMS_FAILED = 'ITEMS_FAILED'
export const ITEMS_LOADING = 'ITEMS_LOADING'

export const itemsSuccessFetch = (results) => ({
  type: ITEMS_FETCH,
  payload: results
})

export const itemsFailedFetch = (error) => ({
  type: ITEMS_FETCH,
  payload: error
})
export const itemsLodingFetch = () => ({
  type: ITEMS_LOADING
})
