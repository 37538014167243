import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #000;
  
  &.active {
    font-weight: bold;
    color: #00f;
  }
`
export default StyledLink
