import { Box, styled } from '@mui/material'
// import waveHaikei from '../icons/wave-haikei.svg'

const StyledHomePageBackground = styled(Box)(({ theme }) => ({
//   aspectRatio: 900 / 450,
//   width: '100%',
//   backgroundRepeat: 'no-repeat',
//   backgroundPosition: 'center',
//   backgroundSize: 'cover',
//   backgroundImage: `url(${waveHaikei})`
  backgroundColor: theme.palette.secondary.main

}))

export default StyledHomePageBackground
