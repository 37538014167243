import * as React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuList from '@mui/material/MenuList'
import { IconButton, Box, Divider } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import StyledNavLink from '../StyledNavlink'
import { StyledMenuItem } from '../styledComponents/StyledItemComponents'
import CategoriesDrop from './CategoriesDrop'
// import { useItems } from '../../hooks/useItems'

export default function Dropdown () {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  // const { fetchItemsByEntityType } = useItems()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  // const handleItemsDataLoad = (path, itemId) => {
  //   fetchItemsByEntityType(path, itemId)
  // }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
        <IconButton
          aria-label='hamburger-menu'
          ref={anchorRef}
          id="hamburger-menu"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <MenuIcon />
        </IconButton>
        <Popper

          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
              }}
            >
              <Paper variant='outlined' sx={{ width: '250px', heigth: '304px' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >

                    <Box sx={{ display: 'flex', justifyContent: 'center', paddingLeft: '15px' }}>
                      <CategoriesDrop />
                    </Box>
                    <Divider flexItem variant='middle' component='li' color='#313131' sx={{ margin: '8px 0' }} />
                    <StyledNavLink to="/branduri" style={{ textDecoration: 'none', color: '#313131' }}><StyledMenuItem onClick={handleClose}>Branduri</StyledMenuItem></StyledNavLink>
                    <Divider flexItem variant='middle' component='li' color='#313131' sx={{ margin: '8px 0' }} />
                    <StyledNavLink to="/promotii" style={{ textDecoration: 'none', color: '#313131' }}><StyledMenuItem onClick={handleClose}>Promoții</StyledMenuItem></StyledNavLink>
                    <Divider flexItem variant='middle' component='li' color='#313131' sx={{ margin: '8px 0' }} />
                    <StyledNavLink to="/pachete" style={{ textDecoration: 'none', color: '#313131' }}><StyledMenuItem onClick={handleClose}>Pachete</StyledMenuItem></StyledNavLink>
                    <Divider flexItem variant='middle' component='li' color='#313131' sx={{ margin: '8px 0' }} />
                    <StyledNavLink to="/lichidare" style={{ textDecoration: 'none', color: '#313131' }}><StyledMenuItem onClick={handleClose}>Lichidare</StyledMenuItem></StyledNavLink>
                    <Divider flexItem variant='middle' component='li' color='#313131' sx={{ margin: '8px 0' }}/>
                    <StyledNavLink to="/" style={{ textDecoration: 'none', color: '#313131' }}><StyledMenuItem onClick={handleClose}>Acasă</StyledMenuItem></StyledNavLink>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </>
  )
}
