import React, { useState } from 'react'
import { Stack, Box, TextField, Button, Paper, Typography, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { theme } from '../../theme'
// import StyledLink from './../StyledLink'
import { PublicLink as StyledLink } from '../PublicLink'
import { useUser } from '../../hooks/useUser'
import { Turnstile } from '@marsidev/react-turnstile'
import { parseEmail, parsePassword } from '../../utils/validation'

const LoginForm = () => {
  const { loginUser } = useUser()
  const [loginMessage, setLoginMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
      turnsitleToken: ''
    }
  })

  const { register, handleSubmit, setValue, formState } = form
  const { errors } = formState

  const handleSuccess = (token) => {
    setValue('turnsitleToken', token)
  }

  const onSubmit = async (data) => {
    setLoginMessage('')
    setLoading(true) // Start loading
    try {
      const message = await loginUser(data)
      setLoginMessage(message)
    } catch (error) {
      setLoginMessage('Login failed: ' + error.message)
    } finally {
      setLoading(false) // Stop loading regardless of outcome
    }
  }

  return (
    <Paper elevation={8} sx={{ padding: ' 30px 30px', display: 'flex', justifyContent: 'center' }}>
        <Stack spacing={2} width={{ xs: 300, sm: 300, md: 350, lg: 350, xl: 350 }}>
            <Box>
              <Typography variant='h5' sx={{ textAlign: 'center', color: theme.palette.fontColor.main, fontSize: '18px', marginBottom: '15px' }}>Bună!</Typography>
              <Typography variant='h5' sx={{ textAlign: 'center', color: theme.palette.fontColor.light, fontSize: '14px' }}>Introduceți emailul și parola</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
            <TextField label="Email"
                size='small'
                inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                type='email' {...register('email', {
                  required: 'Câmp obligatoriu',
                  validate: value => parseEmail(value) === true || parseEmail(value)
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
            />
            <TextField label="Parola"
              size='small'
              inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
              InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
              type="password" {...register('password', {
                required: 'Câmp obligatoriu',
                validate: value => parsePassword(value) === true || parsePassword(value)
              })}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
            <Turnstile
              siteKey="0x4AAAAAAATJwrwbC0zcwPkV"
              injectScript={false}
              scriptOptions={{ id: 'turnstile-script' }}
              onSuccess={handleSuccess}
              options={{
                action: 'submit',
                theme: 'light',
                size: 'normal',
                appearance: 'interaction-only'
              }}
            />
              {loading
                ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress sx={{ color: '#ff0000' }}/>
                </div>
                  )
                : (
                <Button
                  type="submit"
                  variant="contained"
                  color="accent"
                  sx={{ color: 'white', borderRadius: '21px' }}
                >
                  Intrați în cont
                </Button>
                  )}
            </Stack>
            </form>
            {loginMessage && (
              <Typography variant="body1" style={{ textAlign: 'center', color: theme.palette.error.main }}>
                {loginMessage}
              </Typography>
            )}
            <Typography variant='h6' sx={{ textAlign: 'center', color: theme.palette.fontColor.main, fontSize: '14px', fontWeight: 400 }}>Nu aveți cont? <StyledLink to="/registrare"><Typography variant='span' sx={{ color: theme.palette.success.main, fontWeight: 500 }}> Înregistrați aici!</Typography></StyledLink></Typography>
        </Stack>
    </Paper>
  )
}

export default LoginForm
