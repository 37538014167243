import React from 'react'
import {
  Box, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material'
import { theme } from '../../theme.js'

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import PinOutlinedIcon from '@mui/icons-material/PinOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined'
import PropTypes from 'prop-types'

const UserInfo = ({ userData }) => {
  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0
  }
  if (isEmptyObject(userData)) {
    userData = {
      email: '',
      username: '',
      company: '',
      cui: '',
      phone: ''
    }
  }
  return (
    <Box sx={{ width: '100%', padding: { xs: '0 25px', md: '25px' }, bgcolor: theme.palette.primary.main }}>
      <List>
        <ListItem>
          <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(5) }}><PersonOutlineOutlinedIcon /></ListItemIcon>
          <ListItemText primary={`Username: ${userData.username}`} />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(5) }}><BusinessOutlinedIcon /></ListItemIcon>
          <ListItemText primary={`Nume firmă: ${userData.company}`} />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(5) }}><PinOutlinedIcon /></ListItemIcon>
          <ListItemText primary={`CUI: ${userData.cui}`} />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(5) }}><EmailOutlinedIcon /></ListItemIcon>
          <ListItemText primary={`Email: ${userData.email}`} />
        </ListItem>
        <ListItem>
          <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(5) }}><ContactPhoneOutlinedIcon /></ListItemIcon>
          <ListItemText primary={`Nr. de telefon: ${userData.phone ? userData.phone : ''}`} />
        </ListItem>
      </List>
    </Box>
  )
}

UserInfo.propTypes = {
  userData: PropTypes.shape({
    phone: PropTypes.number,
    email: PropTypes.string,
    cui: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    company: PropTypes.string,
    username: PropTypes.string
  })
}
export default UserInfo
