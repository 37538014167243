export const CART_FETCH = 'CART_FETCH'
export const CART_FAILED = 'CART_FAILED'
export const CART_LOADING = 'CART_LOADING'
export const CART_RESET = 'CART_RESET'
export const UPDATE_CART = 'UPDATE_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'

export const cartSuccessFetch = (results) => ({
  type: CART_FETCH,
  payload: results
})

export const cartFailedFetch = (error) => ({
  type: CART_FETCH,
  payload: error
})
export const cartLodingFetch = () => ({
  type: CART_LOADING
})

export const cartReset = () => ({
  type: CART_RESET
})

export const updateCart = (data, count) => ({
  type: UPDATE_CART,
  payload: { ...data, ...count }
})

export const removeFromCart = (id) => ({
  type: REMOVE_FROM_CART,
  payload: id
})
