import { useContext } from 'react'
import { jwtDecode } from 'jwt-decode'
import { AppContext } from '../context/AppProvider'
import { loginUserAction, logoutUserAction, updateUserProfileAction } from '../actions/userActions'
import { useCart } from './useCart'
import { useFavorites } from './useFavorites'

export const useUser = () => {
  const { dispatch } = useContext(AppContext)
  const apiUrl = 'https://api.primusvest.ro/v1/'
  const { getCartItems, resetCartState } = useCart()
  const { getFavorites, resetFavoritesState } = useFavorites()

  const handleUserAuthentication = async (token) => {
    try {
      const userData = jwtDecode(token)
      await getUserData(token, userData.user_id)
      await getCartItems(1)
      await getFavorites(1)
    } catch (error) {
      console.error('Token handling failed:', error)
      handleUserLogout()
    }
  }

  const handleDeleteUser = async () => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const userData = jwtDecode(token)
        deleteUser(token, userData.user_id)
        resetCartState()
        resetFavoritesState()
      } catch (error) {
        console.error('Token handling failed:', error)
        handleUserLogout()
      }
    }
  }

  const handleUserLogout = () => {
    localStorage.removeItem('token')
    dispatch(logoutUserAction())
    resetCartState()
    resetFavoritesState()
  }

  const handleUpdateUserProfile = (payload) => {
    const token = localStorage.getItem('token')
    if (token) {
      try {
        const userData = jwtDecode(token)
        updateUserProfile(token, userData.user_id, payload)
      } catch (error) {
        console.error('Token handling failed:', error)
      }
    }
  }

  const getUserData = async (token, userId) => {
    // dispatch({ type: 'ATTEMPT_NAVIGATE_PROTECTED_ROUTE' })
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const url = `${apiUrl}users/${userId}`
        const response = await fetch(url, {
          method: 'GET',
          headers
        })
        if (response.ok) {
          const resp = await response.json()
          const { data } = resp
          dispatch(loginUserAction(data))
        } else {
          console.error('Error with status code:', response.status)
          handleUserLogout()
        }
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }

  const loginUser = async ({ email, password, turnsitleToken }) => {
    try {
      const response = await fetch(apiUrl + 'login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password, turnsitleToken })
      })
      const { token, message } = await response.json()
      if (token) {
        localStorage.setItem('token', token)
        await handleUserAuthentication(token, dispatch)
      }
      return message
    } catch (error) {
      console.error('Login error:', error)
    }
  }

  const logoutUser = () => {
    localStorage.removeItem('token')
    handleUserLogout()
  }

  const registerUser = async ({ ...data }) => {
    try {
      const response = await fetch(apiUrl + 'register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      const { message, messageType } = await response.json()
      return { message, messageType }
    } catch (error) {
      console.error('Login error:', error)
      return error
    }
  }

  const deleteUser = async (token, userId) => {
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const url = `${apiUrl}users/${userId}`
        const response = await fetch(url, {
          method: 'DELETE',
          headers
        })
        const { data } = await response.json()
        dispatch(logoutUser(data))
        // return message
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }

  const updateUserProfile = async (token, userId, payload) => {
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      try {
        const url = `${apiUrl}users/${userId}`
        const response = await fetch(url, {
          method: 'PUT',
          headers,
          body: JSON.stringify({ data: payload })
        })
        // const { data } =
        await response.json()
        dispatch(updateUserProfileAction(payload))
      } catch (error) {
        console.error('Login error:', error)
      }
    }
  }

  return { loginUser, logoutUser, registerUser, handleUserAuthentication, handleUserLogout, handleDeleteUser, handleUpdateUserProfile }
}
