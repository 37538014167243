import React from 'react'
import { StyledBox } from '../components/styledComponents/StyledItemComponents'
import { Box, Stack, Typography } from '@mui/material'
import { theme } from '../theme'

const Datelegale = () => {
  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main, paddingTop: '30px' }}>
    <StyledBox sx={{ padding: '50px 70px', backgroundColor: theme.palette.primary.main, minHeight: '50vh' }}>
    <Stack spacing={1}>
        <Typography variant='h5' sx={{ fontSize: '18px', paddingBottom: '20px' }}>SC PRIMUSVEST SRL</Typography>
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: 400 }}><span style={{ fontWeight: 500 }}>Cod Unic de Înregistrare:</span> 35750706</Typography>
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: 400 }}><span style={{ fontWeight: 500 }}>Nr. Înmatriculare:</span> J05/485/2016</Typography>
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: 400 }}><span style={{ fontWeight: 500 }}>Sediul:</span> Jud. Bihor, Sat Sârbeşti, Com. Lunca, Sârbeşti, Nr. 72</Typography>
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: 400 }}><span style={{ fontWeight: 500 }}>Magazine:</span> Bulevardul Decebal 41, Oradea, Jud. Bihor - </Typography>
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: 400 }}>str. Seleușului 117, Oradea, Jud. Bihor</Typography>
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: 400 }}><span style={{ fontWeight: 500 }}>Email:</span> office@primusvest.ro</Typography>
        <Typography variant='h6' sx={{ fontSize: '16px', fontWeight: 400 }}><span style={{ fontWeight: 500 }}>Telefon:</span> +40 770 585 736</Typography>
      </Stack>
</StyledBox>
</Box>
  )
}

export default Datelegale
