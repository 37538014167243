import { CART_FETCH, CART_LOADING, CART_FAILED, CART_RESET, UPDATE_CART, REMOVE_FROM_CART } from '../actions/cartActions'

export const initialState = { loading: false, results: [], error: null, total_items: 0, total_price: 0, total_unique_items: 0 }

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_LOADING:
      return { ...state, loading: true }
    case CART_FETCH:
      return {
        ...state,
        loading: false,
        results: action.payload.data,
        total_items: action.payload.total_items,
        total_price: action.payload.total_price,
        total_unique_items: action.payload.total_unique_items
      }
    case CART_FAILED:
      return { ...state, loading: false, results: [], error: action.payload }
    case CART_RESET:
      return initialState
    case UPDATE_CART: {
      const newItem = action.payload
      const itemIndex = state.results.findIndex(item => item._id === newItem._id)

      let updatedResults = [...state.results]
      let updatedTotalItems = state.total_items
      let updatedTotalUniqueItems = state.total_unique_items
      let updatedTotalPrice = state.total_price
      let updatedByAmount = 0

      if (itemIndex !== -1) {
        const existingItem = updatedResults[itemIndex]
        updatedByAmount = newItem.count - existingItem.count

        if (newItem.count <= 0) {
          updatedResults = updatedResults.filter((_, index) => index !== itemIndex)
          updatedTotalItems -= existingItem.count
          updatedTotalUniqueItems -= 1
          updatedTotalPrice -= existingItem.discount.price * existingItem.count
        } else {
          updatedResults[itemIndex] = { ...existingItem, ...newItem, count: newItem.count }
          updatedTotalItems += updatedByAmount
          updatedTotalPrice += newItem.discount.price * updatedByAmount
        }
      } else if (newItem.count > 0) {
        updatedResults.push(newItem)
        updatedTotalItems += newItem.count
        updatedTotalUniqueItems += 1
        updatedTotalPrice += newItem.discount.price * newItem.count
      }

      // Ensure counts and price don't go below 0
      updatedTotalItems = Math.max(0, updatedTotalItems)
      updatedTotalUniqueItems = Math.max(0, updatedTotalUniqueItems)
      updatedTotalPrice = Math.max(0, updatedTotalPrice)

      return {
        ...state,
        results: updatedResults,
        total_items: updatedTotalItems,
        total_unique_items: updatedTotalUniqueItems,
        total_price: updatedTotalPrice,
        loading: false
      }
    }

    case REMOVE_FROM_CART: {
      const itemIdToRemove = action.payload // Assuming payload contains the id of the item to remove
      const existingItemIndex = state.results.findIndex(item => item._id === itemIdToRemove)

      let updatedResults = [...state.results]
      let updatedTotalItems = state.total_items
      let updatedTotalUniqueItems = state.total_unique_items
      let updatedTotalPrice = state.total_price

      if (existingItemIndex !== -1) {
        const existingItem = updatedResults[existingItemIndex]
        // Update counts and total price based on the existing item details
        updatedTotalItems -= existingItem.count // Decrease total_items by the count of the removed item
        updatedTotalUniqueItems -= 1 // Decrease total_unique_items by 1 as we're removing one unique item
        updatedTotalPrice -= existingItem.discount.price * existingItem.count // Decrease total_price

        // Remove the item from the results array
        updatedResults = updatedResults.filter((item, index) => index !== existingItemIndex)
      }

      // Ensure counts and price don't go below 0
      updatedTotalItems = Math.max(0, updatedTotalItems)
      updatedTotalUniqueItems = Math.max(0, updatedTotalUniqueItems)
      updatedTotalPrice = Math.max(0, updatedTotalPrice)
      return {
        ...state,
        results: updatedResults,
        total_items: updatedTotalItems,
        total_unique_items: updatedTotalUniqueItems,
        total_price: updatedTotalPrice,
        loading: false
      }
    }

    default:
      return state
  }
}
