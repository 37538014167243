import React, { useState } from 'react'
import { Stack, Box, TextField, Button, Paper, Typography, CircularProgress } from '@mui/material'
import { useForm } from 'react-hook-form'
import { theme } from '../../theme'
import StyledLink from './../StyledLink'
import { useUser } from '../../hooks/useUser'
import { MuiTelInput } from 'mui-tel-input'
import { parseEmail, parseCUI, parsePassword } from '../../utils/validation'
import { Turnstile } from '@marsidev/react-turnstile'

const RegisterForm = () => {
  const { registerUser } = useUser()
  const [registrationMessage, setRegistrationMessage] = useState({ message: '', messageType: '' })
  const [loading, setLoading] = useState(false)
  // const navigate = useNavigate()

  const [phoneValue, setPhoneValue] = useState('')
  const handleChange = (newValue) => {
    setPhoneValue(newValue)
  }

  const form = useForm({
    defaultValues: {
      cui: '',
      username: '',
      password: '',
      email: '',
      company: ''

    }
  })

  const onSubmit = (data) => {
    setRegistrationMessage('')
    setLoading(true)
    registerUser(data)
      .then(({ message, messageType }) => {
        setRegistrationMessage({ message, messageType })
        if (messageType === 'info') {
          reset()
        }
      })
      .catch(error => setRegistrationMessage({ message: 'Registration failed: ' + error.message, messageType: 'error' }))
      .finally(() => {
        setLoading(false)
      })
  }

  const { register, handleSubmit, setValue, reset, formState } = form
  const { errors } = formState

  const handleSuccess = (token) => {
    setValue('turnsitleToken', token)
  }
  return (
    <Paper elevation={8} sx={{ padding: ' 30px 30px', display: 'flex', justifyContent: 'center', borderRadius: '5px' }}>
        <Stack spacing={2} width={{ xs: 300, sm: 300, md: 350, lg: 350, xl: 350 }}>
            <Box>
            <Typography variant='h5' sx={{ textAlign: 'center', color: theme.palette.fontColor.main, fontSize: '18px', marginBottom: '15px' }}>Bună!</Typography>
              <Typography variant='h5' sx={{ textAlign: 'center', color: theme.palette.fontColor.light, fontSize: '14px' }}>Pentru a vă putea crea un cont nou, introduceți datele de mai jos:</Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
            <TextField label="Username"
                size='small'
                inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                type="text" {...register('username', { required: 'Câmp obligatoriu' })}
                error={!!errors.username}
                helperText={errors.username?.message}
            />
            <TextField label="Nume firmă"
                size='small'
                inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                type="text" {...register('company', { required: 'Câmp obligatoriu' })}
                error={!!errors.company}
                helperText={errors.company?.message}
            />
            <TextField label="CUI"
                size='small'
                inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                type="text" {...register('cui', {
                  required: 'Câmp obligatoriu',
                  validate: value => parseCUI(value) === true || parseCUI(value)
                })}
                error={!!errors.cui}
                helperText={errors.cui?.message}
            />
            <TextField label='Email'
                size='small'
                inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                type='email' {...register('email', {
                  required: 'Câmp obligatoriu',
                  validate: value => parseEmail(value) === true || parseEmail(value)
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
            />
            <MuiTelInput
                size='small'
                value={phoneValue}
                onChange={handleChange}
                defaultCountry='RO'
                onlyCountries='RO'
                inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
            />
            <TextField label="Parola"
                size='small'
                inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                type="password" {...register('password', {
                  required: 'Câmp obligatoriu',
                  validate: value => parsePassword(value) === true || parsePassword(value)
                })}
                error={!!errors.password}
                helperText={errors.password?.message}
            />
            <Turnstile
              siteKey="0x4AAAAAAATJwrwbC0zcwPkV"
              injectScript={false}
              scriptOptions={{ id: 'turnstile-script' }}
              onSuccess={handleSuccess}
              options={{
                action: 'submit',
                theme: 'light',
                size: 'normal',
                appearance: 'interaction-only'
              }}
            />              {loading
              ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress sx={{ color: '#ff0000' }}/>
              </div>
                )
              : (
            <Button
                type="submit"
                variant="contained"
                color="accent"
                sx={{ color: 'white', borderRadius: '21px' }}
            >
                Creați un cont
            </Button>
                )}
            </Stack>
            </form>
            {registrationMessage.message && (
              <Typography variant="body1"
                style={{
                  textAlign: 'center',
                  color: registrationMessage.messageType === 'error' ? theme.palette.error.main : 'green'
                }}>
                {registrationMessage.message}
              </Typography>
            )}
             <Typography variant='h6' sx={{ textAlign: 'center', color: theme.palette.fontColor.main, fontSize: '14px', fontWeight: 400 }}>Aveți deja cont?<StyledLink to='/login'><Typography variant='span' sx={{ color: theme.palette.success.main, fontWeight: 500 }}> Logați-vă aici!</Typography></StyledLink></Typography>
        </Stack>
    </Paper>
  )
}

export default RegisterForm
