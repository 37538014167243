import { useContext, useCallback } from 'react'
import { AppContext } from '../context/AppProvider'
import { settingsSuccessFetch, settingsLodingFetch, settingsFailedFetch } from '../actions/settingsAction'

export const useSettings = () => {
  const { state, dispatch } = useContext(AppContext)

  const apiUrl = 'https://api.primusvest.ro/v1/settings'

  const fetchSettings = useCallback(async () => {
    dispatch(settingsLodingFetch())
    try {
      const response = await fetch(apiUrl)
      const data = await response.json()
      dispatch(settingsSuccessFetch(data))
    } catch (error) {
      console.error('Failed to fetch brands:', error)
      dispatch(settingsFailedFetch(error))
    }
  }, [])

  return { state, fetchSettings }
}
