export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS'
export const FAILED_COLLECTIONS = 'FAILED_COLLECTIONS'
export const LOADING_COLLECTIONS = 'LOADING_COLLECTIONS'

export const collectionsSuccessFetch = (results) => ({
  type: FETCH_COLLECTIONS,
  payload: results
})

export const collectionsFailedFetch = (error) => ({
  type: FAILED_COLLECTIONS,
  payload: error
})

export const collectionsLodingFetch = () => ({
  type: LOADING_COLLECTIONS
})
