import { SETTINGS_FETCH, SETTINGS_FAILED, SETTINGS_LOADING } from '../actions/settingsAction'

export const initialItemsState = { loading: true, data: [], error: null }

export const settingsReducer = (state = initialItemsState, action) => {
  switch (action.type) {
    case SETTINGS_LOADING:
      return { ...state, loading: true }
    case SETTINGS_FETCH:
      return { ...state, loading: false, data: action.payload.data }
    case SETTINGS_FAILED:
      return { ...state, loading: false, data: [], error: action.payload }
    default:
      return state
  }
}
