import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography, Divider, Pagination } from '@mui/material'
import { theme } from '../theme'
import FavoriteCard from '../components/Items/FavoriteCard'
import { useAppContext } from '../context/AppProvider'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { useFavorites } from '../hooks/useFavorites'
import { StyledBox } from '../components/styledComponents/StyledItemComponents'

const Favorite = () => {
  const { state } = useAppContext()
  const [pageNumber, setPage] = useState(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const { getFavorites } = useFavorites()
  const skeletonItemsRange = (start, end) => {
    return Array.from({ length: end - start }, (v, k) => k + start)
  }
  const maxResult = 24
  const totalPages = state.favorites.total ? Math.ceil(state.favorites.total / maxResult) : 0

  useEffect(() => {
    setPage(pageNumber)
    getFavorites(pageNumber)
  }, [searchParams])

  const paginationHandleChange = (event, value) => {
    if (value !== pageNumber) {
      const updatedFilters = { ...Object.fromEntries(searchParams.entries()), page: value.toString() }
      setPage(value)
      // getFavorites(value)
      setSearchParams(updatedFilters)
    }
  }

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: '40px 0' }}>
      <StyledBox>
      <Stack bgcolor={theme.palette.primary.main} p='20px 30px' border='solid 1px' borderColor={theme.palette.borderColor.main} borderRadius="4px" sx={{ display: 'flex', justifyContent: 'center' }}>
       <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='span' fontSize='18px' fontWeight={600} paddingBottom='20px'>Favorite</Typography>
        </Box>
        <Divider orientation='horizontal' aria-hidden="true" color={theme.palette.fontColor.main} />
        <Box sx={{ marginTop: '40px', display: { xs: 'flex', md: 'block' }, justifyContent: { xs: 'center' } }}>
          <Stack>
          {!state.favorites.loading
            ? state.favorites.results?.map((item) =>
            <FavoriteCard key={item._id} item={item}/>
            )
            : skeletonItemsRange(0, 10).map((item, index) =>
                <FavoriteCard key={index} item={item}/>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'center' }} padding='25px 0'>
          <Pagination count={totalPages} page={pageNumber} onChange={paginationHandleChange} />
            </Box>
      </Stack>
      </Box>
      </Stack>
      </StyledBox>
    </Box>
  )
}
Favorite.propTypes = {
  totalPages: PropTypes.number,
  pageNumber: PropTypes.number,
  onChangePagination: PropTypes.func,
  filtersData: PropTypes.object
}

export default Favorite
