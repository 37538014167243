import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { Facebook, LinkedIn, YouTube } from '@mui/icons-material'
// import { Link } from 'react-router-dom'
import { PublicLink as Link } from '../PublicLink'
import { theme } from '../../theme'
import Sal1 from '../../icons/anpc-sal1-1.png'
import Sal2 from '../../icons/anpc-sol.png'
import { ImageLoader } from '../ImageLoader'
const Footer = () => {
  return (
    <footer>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '2% 5%',
          lineHeight: '3rem',
          backgroundColor: '#E4E4E4'
        }}>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
              textAlign: { xs: 'center' }

            }}>
                <Link to="/datelegale" style={{ textDecoration: 'none', color: '#313131' }}><Typography variant='h6' sx={{ fontWeight: 600, fontSize: '16px' }}>
                    PRIMUSVEST SRL
                </Typography></Link>
                <Box sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' }
                }}>
                    <a aria-label='anpc' href='https://anpc.ro/' target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'none', color: '#313131', fontSize: '14px' }}><Typography variant='span'
                    sx={{
                      padding: '0 15px',
                      fontWeight: 400,
                      color: '#313131'
                    }}>
                    ANPC
                </Typography></a>
                    <Link to="/gdpr" style={{ textDecoration: 'none', color: '#313131', fontSize: '14px' }}><Typography variant='span'
                    sx={{
                      padding: '0 15px',
                      fontWeight: 400,
                      fontSize: '14px',
                      color: '#313131'
                    }}>
                    GDPR - Protecția datelor
                    </Typography></Link>
                    <Link to="termeni" style={{ textDecoration: 'none', color: '#313131', fontSize: '14px' }}><Typography variant='span'
                    sx={{
                      padding: '0 15px',
                      fontWeight: 400,
                      fontSize: '14px',
                      color: '#313131'
                    }}>Termeni și condiții</Typography></Link>
                </Box>
        </Box>
        <Divider orientation='horizontal'aria-hidden="true" color={theme.palette.fontColor.main} />
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: { xs: 'center', md: 'start' }
        }}>
            <Box sx={{ marginTop: '1rem', marginBottom: '2rem' }}>
                <Typography variant='h6' sx={{ fontSize: '16px' }}>
                &copy; {new Date().getFullYear()} Primusvest srl. All rights reserved.
                </Typography>
                <Typography variant='h6' sx={{ fontStyle: 'italic', fontSize: '14px', fontWeight: 400 }}>Dezvoltat de AunerTech Solutions</Typography>
            </Box>
            <Box sx={{ marginTop: '1rem', marginBottom: '2rem' }}>
                <a aria-label='facebook' href='https://www.facebook.com/www.primusvest.ro' target='_blank' rel='noopener noreferrer'><Facebook sx={{
                  color: '#616161',
                  marginRight: '20px',
                  transition: 'color 0.3s ease',
                  '&:hover': { color: '#313131' }
                }}/></a>
                <a aria-label='linkedIn' href='https://ro.linkedin.com/in/florin-vidican-2b7340257' target='_blank' rel='noopener noreferrer'><LinkedIn sx={{
                  color: '#616161',
                  marginRight: '20px',
                  transition: 'color 0.3s ease',
                  '&:hover': { color: '#313131' }
                }}/></a>
                <a aria-label='youtube' href='https://www.youtube.com/@florinvidican7174' target='_blank' rel='noopener noreferrer'><YouTube sx={{
                  color: '#616161',
                  marginRight: '15px',
                  transition: 'color 0.3s ease',
                  '&:hover': { color: '#313131' }
                }}/></a>
            </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: { xs: 'center' }

            }}>
            <Box>
              <a aria-label='anpc' href='https://anpc.ro/ce-este-sal/' target='_blank' rel='noopener noreferrer'>
              <ImageLoader
                src={Sal1}
                alt="Solutionarea online a litigiilor"
                className="" // Ensure this class or inline styles set width and height to 100% if needed
                height={50} margin={5}
              />
              </a>
              <a aria-label='consumer' href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO' target='_blank' rel='noopener noreferrer'>
              <ImageLoader
                src={Sal2}
                alt="Solutionarea online a litigiilor"
                className="" // Ensure this class or inline styles set width and height to 100% if needed
                height={50} margin={5}
              />
              </a>
            </Box>
            </Box>
        </Box>
    </footer>
  )
}

export default Footer
