import { React, useRef, useState, useCallback } from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Box, IconButton, styled } from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { theme } from '../../theme'
import { Currency } from 'react-intl-number-format'
import PropTypes from 'prop-types'
import StyledLink from '../StyledLink'
import { DiscountLabel, BrandName, StockLabel } from '../styledComponents/StyledItemComponents'
import { useAppContext } from '../../context/AppProvider'
import { useCart } from '../../hooks/useCart'
import { useFavorites } from '../../hooks/useFavorites'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { debounce } from 'lodash'
import { ImageLoader } from '../ImageLoader'
import ItemAvatar from '../../icons/tools-hardware-128.png'

const ProductCard = ({ item }) => {
  const { state } = useAppContext()
  const { addItemToFavorites, removeItemFromFavorites } = useFavorites()
  const { updateCartItem } = useCart()
  const itemData = useRef(item)
  const [addedToCart, setAddedToCart] = useState(false)

  const isItemInFavorites = state.favorites.results?.some(favoriteItem => favoriteItem._id === item._id)

  // const BrandName = styled(Typography)(({ theme }) => ({
  //   fontSize: '12px',
  //   fontWeight: 500,
  //   textTransform: 'uppercase',
  //   paddingBottom: '15px'
  // }))

  const ProductName = styled(Typography)(({ theme }) => ({
    fontSize: '14px',
    lineHeight: '1.55',
    fontWeight: 600,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis'

  }))

  const debouncedCartAction = useCallback(debounce((action, data) => {
    action(data)
    setAddedToCart(false) // Example state update, adjust based on your actual logic
  }, 1000, {
    leading: false,
    trailing: true
  }), [])

  // Handle adding to cart with debounced function
  const handleAddToCart = () => {
    setAddedToCart(true) // Immediate state update for UI feedback
    debouncedCartAction(updateCartItem, itemData)
  }

  const debouncedFavoriteAction = useCallback(debounce((action, data) => {
    action(data)
  }, 200, {
    leading: false,
    trailing: true
  }), [])

  const handleAddToFavorites = () => {
    const action = isItemInFavorites ? removeItemFromFavorites : addItemToFavorites
    debouncedFavoriteAction(action, itemData)
  }

  return (
    <Card item_id={itemData.current._id} sx={{ width: '250px', margin: '20px 0', paddingBottom: '20px', height: '450px', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ height: '41px' }}>
        {item.discount.type && (
          <DiscountLabel>
            -{ item.discount.value }{item.discount.type === 'percent' ? '%' : ' RON' }
          </DiscountLabel>
        )}
      </Box>
      <Box sx={{ position: 'relative' }}>
        <CardMedia
        component='img'
        fetchpriority='high'
        loading="eager"
        sx={{ height: '200px', width: '100%', objectFit: 'contain', padding: item.cover ? '0' : '70px' }}
        image={item.cover ? item.cover : ItemAvatar}
        title={item.name}
      />
      </Box>
      <CardContent sx={{ paddingBottom: 0 }}>
        <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        { !item.brand?.logo
          ? <BrandName>{item.brand.name}</BrandName>
          : <ImageLoader src={item.brand.logo} alt={item.brand.name} style={{ height: '18px', marginRight: '5px', objectFit: 'contain' }}/>
        }
        </Typography>
        {item && (
        <StyledLink to={{ pathname: `/items/${item._id}` }} >

        <ProductName>
          {item.name}
        </ProductName>
        </StyledLink>
        )}
        { item.discount?.type
          ? <Box sx={{ alignItems: 'center' }}>
          <Typography variant='h6' sx={{ color: theme.palette.accent.main, fontSize: '16px', paddingRight: '20px', fontWeight: 400 }}>
            <Currency locale="ro-RO" currency="RON">
            {item.discount.price}
            </Currency>
          </Typography>
          <Typography variant='h6' sx={{ textDecoration: 'line-through', fontSize: '12px', paddingLeft: '2px', fontWeight: 400 }}>
            <Currency locale="ro-RO" currency="RON">
            { item.price }
            </Currency>
            </Typography>
          </Box>
          : <Box sx={{ alignItems: 'center' }}>
          <Typography variant='span' sx={{ color: theme.palette.accent.main, fontSize: '16px', paddingRight: '20px' }}>
            <Currency locale="ro-RO" currency="RON">
            {item.price}
            </Currency>
          </Typography>
          </Box>
        }
      </CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <StockLabel sx={{
        position: 'relative',
        color: item.stock >= 3
          ? theme.palette.success.main
          : item.stock > 0 && item.stock < 3
            ? theme.palette.warning.main
            : theme.palette.accent.main
      }}>
        {item.stock >= 3
          ? 'În stoc!'
          : item.stock > 0 && item.stock < 3
            ? 'Stoc limitat!'
            : 'Ultimul în stoc!'}
      </StockLabel>
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px', position: 'relative' }}>
        <IconButton aria-label='add to favorites' onClick={handleAddToFavorites}>
          {isItemInFavorites ? <FavoriteIcon /> : <FavoriteBorderIcon /> }
        </IconButton>
        <IconButton
          aria-label="add to cart"
          onClick={handleAddToCart}
          disabled={item.stock === 0}
        >
        {addedToCart ? <CheckCircleIcon /> : <AddShoppingCartIcon />}
        </IconButton>
      </CardActions>
    </Box>
    </Card>
  )
}
ProductCard.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number,
    stock: PropTypes.number,
    discount: PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.number,
      price: PropTypes.number
    }),
    brand: PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
      logo: PropTypes.string
    }),
    category: PropTypes.shape({
      name: PropTypes.array,
      link: PropTypes.array
    }),
    cover: PropTypes.string,
    images: PropTypes.array
    // Define other properties of an item here if necessary
  }).isRequired
}
export default ProductCard
