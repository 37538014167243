import { useEffect, useState } from 'react'

export const useScript = (src) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Check if the script is already present in the document
    if (document.querySelector(`script[src="${src}"]`)) {
      setLoaded(true)
      return
    }

    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.id = 'turnstile-script'

    const handleLoad = () => setLoaded(true)
    const handleError = (e) => setError(e)

    script.addEventListener('load', handleLoad)
    script.addEventListener('error', handleError)

    document.body.appendChild(script)

    return () => {
      script.removeEventListener('load', handleLoad)
      script.removeEventListener('error', handleError)
      document.body.removeChild(script)
    }
  }, [src])

  return { loaded, error }
}
