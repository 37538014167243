import React from 'react'
import StyledLink from './StyledLink'
import { useAppContext } from '../context/AppProvider'
import PropTypes from 'prop-types'

export const ProtectedLink = ({ to, children }) => {
  const { dispatch } = useAppContext()

  const handleClick = (e) => {
    // Here you can check if the navigation is to a protected route
    // and if so, prevent the default action and dispatch an action instead
    dispatch({ type: 'ATTEMPT_NAVIGATE_PROTECTED_ROUTE', payload: { to } })
  }

  return <StyledLink to={to} onClick={handleClick}>{children}</StyledLink>
}
ProtectedLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.object
}
