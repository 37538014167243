import React from 'react'
import HeroGrid from '../components/Hero/HeroGrid'
import { Box } from '@mui/material'
import { theme } from '../theme'
import StyledHomePageBackground from '../components/StyledHomePageBackground'

const Home = () => {
  return (
      <StyledHomePageBackground >
        <Box sx={{ display: 'flex', justifyContent: 'center', bgcolor: theme.palette.secondary.main, paddingTop: '5px' }}>
            <HeroGrid />
        </Box>
    </StyledHomePageBackground>
  )
}

export default Home
