import React, { useEffect, useState } from 'react'
import './Searchbox.css'
import { SlMagnifier } from 'react-icons/sl'
import TextField from '@mui/material/TextField'
import { Autocomplete } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
// import { theme } from '../../../theme';
// import { useAppContext } from '../../../context/AppProvider' // This is the new import
import { useItems } from '../../../hooks/useItems'
import { debounce } from 'lodash' // Make sure to install lodash for debouncing
import { Link, useNavigate, createSearchParams } from 'react-router-dom'
import { ImageLoader } from '../../ImageLoader'
import PropTypes from 'prop-types'

const Searchbox = ({ id }) => {
  // const { state } = useAppContext()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])

  const { searchItems, fetchItemsByEntityType } = useItems()
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const handleItemsDataLoad = (path, itemId) => {
    fetchItemsByEntityType('search', encodeURIComponent(itemId.trim()))
    const sanitizedInput = inputValue.trim()
    navigate({
      pathname: path,
      search: createSearchParams({
        search: sanitizedInput
      }).toString()
    })
  }

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      if (inputValue.length >= 1) {
        setIsLoading(true) // Set loading true before the API call
        searchItems(encodeURIComponent(inputValue.trim())).then(fetchedItems => {
          setOptions(fetchedItems || [])
          setIsLoading(false) // Set loading false after data is fetched
        })
      } else {
        setOptions([]) // Clear options if input length is less than 3
      }
    }, 500) // 500ms debounce

    debouncedFetch()
    return () => {
      debouncedFetch.cancel()
    }
  }, [inputValue, searchItems])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const sanitizedInput = encodeURIComponent(inputValue.trim())
      if (sanitizedInput) {
        handleItemsDataLoad('/collections', sanitizedInput)
        event.preventDefault() // Prevent default form submission if inside a form
      }
    }
  }
  const handleInputChange = (event, newInputValue) => {
    setOpen(true)
    setInputValue(newInputValue)
  }

  const handleIsEqual = (option, value) => {
    if (option.name === value.name) {
      return true
    }
    return false
  }
  useEffect(() => {
    let active = true

    if (!isLoading) {
      return undefined
    }

    (async () => {
      if (active) {
        setOptions([...options])
      }
    })()

    return () => {
      active = false
    }
  }, [isLoading])

  return (
    <div className='search-wrap'>
        <div className='search-box'>

        <Autocomplete
        freeSolo
        id={`search-box-${id}`}
        size="small"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => { setOpen(false) }
        }
        inputValue={inputValue}
        onInputChange={handleInputChange}
        isOptionEqualToValue={handleIsEqual}
        getOptionLabel={(option) => option.name || ''}
        options={options}
        loading={isLoading}
        renderOption={(props, option) => (
          <li {...props}>
            <Link to={`/items/${option._id}`}style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'inherit' }}>
              <ImageLoader src={option.cover} alt={option.name} style={{ marginRight: 10, width: 40, height: 40, objectFit: 'cover' }} loading="eager"/>
              {option.name}
            </Link>
          </li>
        )}
        renderInput={(params) => (
          <TextField
          {...params}
          placeholder="Căutați în catalogul nostru"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
            ),
            onKeyDown: handleKeyPress
          }}
          />
        )}
      />
      {/* StyledNavLink to={`/brands/${item.name}`} onClick={() => handleItemsDataLoad('brands', item.link)}> */}
            <div className="btn btn-common">
                <SlMagnifier className='nav-icons'
                onClick={() => handleItemsDataLoad('/collections', inputValue.trim())}
                 style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', margin: '0', color: '#fff' }}
                />
            </div>

        </div>
    </div>
  )
}

Searchbox.propTypes = {
  id: PropTypes.string.isRequired
}

export default Searchbox
