import React, { useRef, useState, useEffect } from 'react'
import { Grow, Paper, Popper, MenuList, ClickAwayListener, Divider, MenuItem } from '@mui/material'
import { useTheme } from '@mui/system'
import StyledNavLink from '../StyledNavlink'
import NavButton from './NavButtonStyle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import CircularProgress from '@mui/material/CircularProgress'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useAppContext } from '../../context/AppProvider' // This is the new import
import { useItems } from '../../hooks/useItems'

export default function CategoriesDrop () {
  const { state } = useAppContext()
  const { fetchItemsByEntityType } = useItems()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const loading = open && state.collections.categories.data.length === 0

  const handleItemsDataLoad = (path, itemId) => {
    fetchItemsByEntityType(path, itemId)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
        <div ref={anchorRef}>
        <NavButton

          id="categories-button"
          aria-controls={open ? 'categories-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={{ transition: 'color 0.3s ease', '&:hover': { color: '#e20613' }, '&:active': { color: '#e20613' }, fontSize: '16px' }}
        >
          Categorii
          {matches
            ? <KeyboardArrowRightIcon sx={{ fontSize: '18px' }} />
            : <KeyboardArrowDownIcon sx={{ fontSize: '18px' }} />
          }
        </NavButton>
        </div>
        <Popper

          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement='bottom-start'
          transition
          disablePortal
          sx={{ zIndex: 1200 }}
        >

          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom'
              }}
            >
              <Paper variant='outlined' sx={{ width: '250px', maxHeight: '70vh', overflowY: 'auto', scrollbarWidth: 'thin' }}>
              <React.Fragment>
                    {loading && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0
                        }}
                      >
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          </div>
                    )}
                  </React.Fragment>
                <ClickAwayListener onClickAway={handleClose}>

                  <MenuList
                    autoFocusItem={open}
                    id="categories-menu"
                    aria-labelledby="categories-button"
                    onKeyDown={handleListKeyDown}
                  >
                <StyledNavLink to={'/categories/toate-categoriile'} onClick={() => handleItemsDataLoad(null, null)}>
                  <MenuItem
                    onClick={handleClose}
                    sx={{ padding: '12px 15px', fontWeight: 500, transition: 'color 0.3s ease', '&:hover': { color: '#e20613' } }}>
                      Toate categoriile
                  </MenuItem>
                    <Divider flexItem variant='middle' component='li' color='#313131' sx={{ margin: '8px 0' }} />
                </StyledNavLink>
                   {
                   state.collections.categories.data.map((item, index) => (
                    <StyledNavLink to={`/categories/${item.link}`} key={item._id} onClick={() => handleItemsDataLoad('categories', item.link)}>
                      <MenuItem
                      onClick={handleClose}
                      sx={{ padding: '12px 15px', fontWeight: 500, transition: 'color 0.3s ease', '&:hover': { color: '#e20613' } }}>
                        {item.name}
                      </MenuItem>
                      {index === state.collections.categories.data.length - 1
                        ? null
                        : <Divider flexItem variant='middle' component='li' color='#313131' sx={{ margin: '8px 0' }} /> }
                    </StyledNavLink>
                   ))}

                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </>
  )
}
