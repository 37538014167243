import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/swiper-bundle.css'
import './ImageSlider.css'
import ImageMagnifier from '../ImageMagnifier/ImageMagnifier'
import PropTypes from 'prop-types'

export default function ImageSlider ({ images }) {
  return (
    <>
      <Swiper
        navigation={true}
        pagination={{ clickable: true }}
        className="mySwiper"
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        // style={{
        //   '--swiper-pagination-color': '#FFBA08',
        //   '--swiper-pagination-bullet-inactive-color': '#999999',
        //   '--swiper-pagination-bullet-inactive-opacity': '1',
        //   '--swiper-pagination-bullet-size': '8px',
        //   '--swiper-pagination-bullet-horizontal-gap': '6px'
        // }}
      >
        {images && images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="image-container">
              <ImageMagnifier imageUrl={image} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
ImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
}
