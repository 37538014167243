import React, { useState, useEffect } from 'react'
import { Box, Stack, Typography, Divider, Button, Pagination } from '@mui/material'
import { theme } from '../theme'
import ShopCard from '../components/Items/ShopCard'
import SendIcon from '@mui/icons-material/Send'
import { useAppContext } from '../context/AppProvider'
import { useCart } from '../hooks/useCart'
import { useSearchParams } from 'react-router-dom'
import { StyledBox } from '../components/styledComponents/StyledItemComponents'
import { Currency } from 'react-intl-number-format'

const Cosulmeu = () => {
  const { state } = useAppContext()
  const { getCartItems, sendOrder } = useCart()
  const [pageNumber, setPage] = useState(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const [orderResponse, setOrderResponse] = useState({ message: '', type: '' })

  const maxResult = 24
  const totalPages = state.cart.total_unique_items ? Math.ceil(state.cart.total_unique_items / maxResult) : 0
  const skeletonItemsRange = (start, end) => {
    return Array.from({ length: end - start }, (v, k) => k + start)
  }
  useEffect(() => {
    setPage(pageNumber)
    getCartItems(pageNumber)
  }, [])

  const paginationHandleChange = (event, value) => {
    if (value !== pageNumber) {
      const updatedFilters = { ...Object.fromEntries(searchParams.entries()), page: value.toString() }
      setPage(value)
      getCartItems(value)
      setSearchParams(updatedFilters)
    }
  }

  const handleSendOrder = () => {
    if (state.user.data && state.user.data._id) {
      const orderData = {
        user_id: state.user.data._id,
        total_price: state.cart.total_price,
        data: state.cart.results.map(item => ({
          name: item.name,
          count: item.count.toString(),
          price: item.price.toString()
        }))
      }
      sendOrder(orderData).then(({ message, statusCode }) => {
        if (statusCode === 200) {
          setOrderResponse({ message, type: 'success' })
        } else if (statusCode >= 400) {
          setOrderResponse({ message: 'Ceva a mers greșit. Vă rugăm să încercați din nou mai târziu sau contactați-ne la adresa office@primusvest.ro pentru asistență.', type: 'error' })
        }
      })
    } else {
      console.error('User data is missing or incomplete')
    }
  }

  return (
    <>
    {orderResponse.message && (
    <Box sx={{ padding: '20px 0' }}>
        <Typography
          variant="body1"
          sx={{
            color: orderResponse.type === 'success' ? 'green' : 'red',
            textAlign: 'center'
          }}
        >
        {orderResponse.message}
        </Typography>
      </Box>
    )}
    <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: '40px 0' }}>
      <StyledBox>
      <Stack bgcolor={theme.palette.primary.main} p='20px 30px' border='solid 1px' borderColor={theme.palette.borderColor.main} borderRadius="4px" sx={{ position: 'relative' }}>
       <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='span' fontSize='18px' fontWeight={600} paddingBottom='20px'>Coșul meu</Typography>
        </Box>
        <Divider orientation='horizontal' aria-hidden="true" color={theme.palette.fontColor.main} />
        <Box sx={{ marginTop: '40px', marginBottom: '80px', display: { xs: 'flex', md: 'block' }, justifyContent: { xs: 'center' } }}>
          <Stack>
        {!state.cart.loading
          ? state.cart.results?.map((item) =>
            <ShopCard key={item._id} item={item}/>
          )
          : skeletonItemsRange(0, 10).map((item, index) =>
          <ShopCard key={index} item={item}/>
          )}
            <Box sx={{ display: 'flex', justifyContent: 'center' }} padding='25px 0'>
            <Pagination count={totalPages} page={pageNumber} onChange={paginationHandleChange} />
            </Box>
          </Stack>
        <Stack sx={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: '60px', right: '25px' }}>
          <Typography variant='span' sx={{ color: theme.palette.fontColor.main, fontSize: '14px', fontWeight: 400, display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              TOTAL: <span style={{ color: theme.palette.accent.main, fontSize: '16px', marginLeft: '10px' }}><Currency locale="ro-RO" currency="RON">{state.cart.total_price}</Currency></span>
          </Typography>
          <Button
                variant="contained"
                size='small'
                onClick={handleSendOrder}
                disabled={!state.cart.results || state.cart.results.length === 0}
                color="accent"
                endIcon={<SendIcon />}
                sx={{ color: 'white', borderRadius: '18px', height: 'auto', marginBottom: '15px' }}>
            Trimite email
          </Button>
          </Stack>
          <Typography variant='span'
            sx={{ position: 'absolute', bottom: '30px', right: '25px', fontSize: '12px', marginLeft: '10px' }}>
              *Prin apăsarea acestui buton, cererea dvs. va fi trimisă automat la adresa office@primusvest.ro
          </Typography>
      </Box>
      </Stack>
      </StyledBox>
    </Box>
  </>
  )
}

export default Cosulmeu
