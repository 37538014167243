import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #000;
  
  &.active {
    font-weight: bold;
    color: #e20613;
  }
`
export default StyledNavLink
