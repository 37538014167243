import React, { useState } from 'react'
import { Box, List, ListItem, Typography, IconButton, Menu, MenuItem, Divider } from '@mui/material'
import { theme } from '../../theme.js'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import LockResetIcon from '@mui/icons-material/LockReset'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Logout from '@mui/icons-material/Logout'
import PersonRemoveIcon from '@mui/icons-material/PersonRemove'
import PropTypes from 'prop-types'
import PersonOutline from '@mui/icons-material/PersonOutline.js'
import DeleteAccountDialog from './DeleteAccountDialog' // Import the dialog component
import { useUser } from '../../hooks/useUser.js'

const Sidebar = ({ activeForm, setActiveForm }) => {
  const { handleDeleteUser, handleUserLogout } = useUser()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleOpenDialog = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleDeleteAccount = () => {
    handleDeleteUser()
    handleCloseDialog()
    handleClose()
  }

  const handleLogout = () => {
    handleUserLogout()
    handleClose()
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box sx={{ width: '255px', minHeight: { md: '55vh' }, marginTop: '20px', padding: '20px', bgcolor: theme.palette.primary.main }}>
     <IconButton
          id="basic-button"
          aria-label='basic menu'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{ paddingBottom: '13px' }}
          >
          <MoreVertIcon />
      </IconButton>
      <Typography variant='span' sx={{ fontSize: '18px', fontWeight: 500 }}>Profilul meu</Typography>
        <Menu
        elevation={1}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={handleOpenDialog} sx={{ padding: '5px 25px' }}> <PersonRemoveIcon sx={{ color: theme.palette.fontColor.main, fontSize: '22px', marginBottom: '2px', marginRight: '8px' }} />Șterge contul</MenuItem>
        <DeleteAccountDialog open={dialogOpen} onClose={handleCloseDialog} onConfirm={handleDeleteAccount} />
        <Divider variant="middle" component="li" sx={{ margin: '8px 0' }} />
        <MenuItem onClick={handleLogout} sx={{ padding: '5px 25px' }}> <Logout sx={{ color: theme.palette.fontColor.main, fontSize: '22px', marginBottom: '2px', marginRight: '8px' }} />Logout</MenuItem>
      </Menu>
      <Divider color={ theme.palette.fontColor.main } variant='middle' />
        <Box sx={{ padding: '10px 15px' }}>
        <List component="nav">
          <ListItem onClick={() => activeForm === 'UserData' ? setActiveForm(null) : setActiveForm('UserData')} >
            <Typography sx={{ cursor: 'pointer', fontSize: '16px', fontWeight: 500, color: theme.palette.fontColor.main, '&:hover': { color: theme.palette.accent.main }, ...(activeForm === 'UserData' && { color: theme.palette.accent.main }) }}><span style={{ paddingRight: '5px' }}><PersonOutline /></span> Datele contului </Typography>
          </ListItem>
        <ListItem onClick={() => activeForm === 'Profile' ? setActiveForm(null) : setActiveForm('Profile')} >
          <Typography sx={{ cursor: 'pointer', fontSize: '16px', fontWeight: 500, color: theme.palette.fontColor.main, '&:hover': { color: theme.palette.accent.main }, ...(activeForm === 'Profile' && { color: theme.palette.accent.main }) }}><span style={{ paddingRight: '5px' }}><AutorenewIcon /></span> Modificare date </Typography>
        </ListItem>
        <ListItem onClick={() => activeForm === 'Password' ? setActiveForm(null) : setActiveForm('Password')}>
            <Typography sx={{ cursor: 'pointer', fontSize: '16px', fontWeight: 500, color: theme.palette.fontColor.main, '&:hover': { color: theme.palette.accent.main }, ...(activeForm === 'Password' && { color: theme.palette.accent.main }) }}><span style={{ paddingRight: '5px' }}><LockResetIcon /></span> Resetare parolă </Typography>
        </ListItem>
        {/* Add more items as needed */}
        </List>
      </Box>
    </Box>
  )
}
Sidebar.propTypes = {
  setActiveForm: PropTypes.func,
  activeForm: PropTypes.string
}
export default Sidebar
