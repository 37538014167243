import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from '@mui/material'

export const ImageLoader = ({ src, alt, width, height, margin, maxRetries = 3, retryDelay = 2000, className = '', style }) => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [attempt, setAttempt] = useState(0)

  useEffect(() => {
    setLoaded(false)
    setError(false)
    setAttempt(0)
  }, [src])

  useEffect(() => {
    if (attempt < maxRetries) {
      const img = new Image()
      img.src = src
      img.onload = () => setLoaded(true)
      img.onerror = () => {
        setTimeout(() => {
          setAttempt(prevAttempt => prevAttempt + 1)
        }, retryDelay)
      }
    } else {
      setError(true)
    }
  }, [attempt, maxRetries, retryDelay, src])

  const imageStyle = {
    ...style,
    // display: error ? 'none' : 'block',
    ...(margin && { margin }), // Add margin if it's provided
    ...(height && { height }), // Add height if it's provided
    ...(width && { width }) // Add width if it's provided
  }

  return (
    <>
      {!loaded && !error && (
        <Skeleton variant="rectangular" width={width || '100%'} height={height || 'auto'} animation="wave" />
      )}
      {(loaded || error) && (
        <img
          fetchpriority='high'
          src={src}
          alt={alt}
          className={`${className} ${!loaded ? 'hidden' : ''}`}
          style={imageStyle}
          onLoad={() => setLoaded(true)}
          onError={() => setError(true)}
        />
      )}
    </>
  )
}

ImageLoader.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  maxRetries: PropTypes.number,
  retryDelay: PropTypes.number,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}
