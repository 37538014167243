import { useCallback, useContext } from 'react'
import {
  itemsSuccessFetch, itemsFailedFetch, itemsLodingFetch
} from '../actions/itemsActions'
import { AppContext } from '../context/AppProvider'

export const useItems = () => {
  const { dispatch } = useContext(AppContext)

  const apiUrl = 'https://api.primusvest.ro/v1/items'

  const searchItems = useCallback(async (searchTerm = '') => {
    try {
      const response = await fetch(`${apiUrl}?search=${searchTerm}`)
      const data = await response.json()
      return data.data
    } catch (error) {
      console.error('Failed to fetch items:', error)
      return []
    }
  }, [apiUrl])

  const fetchItemsByEntityType = useCallback(async (queryParam, value, filters) => {
    let url = apiUrl
    let first = true
    if (queryParam !== null && value !== null) {
      first = false
      url = `${apiUrl}?${queryParam}=${value}`
    }

    if (filters && typeof filters === 'object' && Object.keys(filters).length > 0) {
      Object.entries(filters).forEach(([key, value]) => {
        url += first
          ? '?' + `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          : '&' + `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        first = false
      })
    }
    dispatch(itemsLodingFetch())
    try {
      const response = await fetch(`${url}`)
      const data = await response.json()
      dispatch(itemsSuccessFetch(data))
    } catch (error) {
      console.error('Failed to fetch items:', error)
      dispatch(itemsFailedFetch(error))
    }
  }, [])

  const fetchItemsById = useCallback(async (itemId) => {
    try {
      const response = await fetch(`${apiUrl}/${itemId}`)
      const data = await response.json()
      return { data, error: null }
    } catch (error) {
      console.error('Failed to fetch items:', error)
      return { data: null, error }
    }
  }, [])

  return { searchItems, fetchItemsByEntityType, fetchItemsById }
}
