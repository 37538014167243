import React from 'react'
import { Button, Grid, Paper, Box, styled, Typography, Divider, Skeleton } from '@mui/material'
import { theme } from '../theme'
import { NumericFormat } from 'react-number-format'
import { Link } from 'react-router-dom'

import { useAppContext } from '../context/AppProvider'
import { useItems } from '../hooks/useItems'
import { ImageLoader } from '../components/ImageLoader'

const Brands = () => {
  const { state } = useAppContext()
  const { fetchItemsByEntityType } = useItems()

  const skeletonItemsRange = (start, end) => {
    return Array.from({ length: end - start }, (v, k) => k + start)
  }

  const StyledBox = styled(Box)({
    width: '100%',
    maxWidth: '1500px',
    display: 'flex',
    justifyContent: 'center'

  })

  const handleItemsDataLoad = (path, itemId) => {
    fetchItemsByEntityType(path, itemId)
  }
  const result = state.collections.brands.data.reduce((acc, item) => {
    if (typeof item.maxPrice === 'number' && item.maxPrice >= acc.maxPrice) {
      acc.maxPrice = item.maxPrice
    }
    if (typeof item.minPrice === 'number' && item.minPrice <= acc.minPrice) {
      acc.minPrice = item.minPrice
    }
    acc.totalCount += item.totalCount
    return acc
  }, { maxPrice: -Infinity, totalCount: 0, minPrice: Infinity }) // Initial value

  if (state.collections.brands.loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: theme.palette.secondary.main, padding: '40px 0' }}>
        <StyledBox>
          <Grid container spacing={2} sx={{ maxWidth: 1200, margin: '0 auto' }}>
            {/* Assuming you want to show 3 skeleton placeholders for example */}
            {skeletonItemsRange(0, 24).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper elevation={3} sx={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Skeleton variant="rectangular" width={210} height={118} />
                  <Skeleton variant="text" width={210} sx={{ my: 2 }} />
                  <Skeleton variant="text" width={210} />
                  <Skeleton variant="text" width={210} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </StyledBox>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: theme.palette.secondary.main, padding: '40px 0' }}>
      <StyledBox>
        <Grid container spacing={{ xs: 1, sm: 1, md: 1, lg: 5, xl: 5, smalldesktop: 1 }} sx={{ display: 'flex', justifyContent: 'center', marginLeft: { xs: 0, sm: '15px', lg: '25px', xl: '0', smalldesktop: '10px' } }}>
          <Grid item>
            <Paper elevation={3} sx={{ padding: { xs: '10px', md: '20px' } }}>
            <Link to={'/brands/toate-brandurile'} onClick={() => handleItemsDataLoad(null, null)}>
              <Button
                variant="text" sx={{ color: theme.palette.fontColor.main, fontSize: '34px', lineHeight: '35px', fontWeight: 600, width: '280px', height: '160px' }}
                >Toate brandurile
              </Button>
              </Link>
              <Divider color={theme.palette.fontColor.main} sx={{ margin: '20px 0' }} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px', fontSize: '14px', fontWeight: 500 }}>
                <Typography variant='span'>{result.totalCount} prod.</Typography>
                <Divider flexItem orientation='vertical' color={theme.palette.fontColor.main} />
                <Typography variant='span'>
                <NumericFormat value={result.minPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} />
                  <span style={{ marginLeft: '5px' }}>-</span>
                  <span style={{ marginLeft: '5px' }}><NumericFormat value={result.maxPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} /></span>
                  <span style={{ marginLeft: '5px' }}>RON</span>
                </Typography>
              </Box>
            </Paper>
          </Grid>
          {state.collections.brands.data.map((brand) => (
            <Grid item key={brand._id}>
              <Paper elevation={3} sx={{ padding: { xs: '10px', md: '20px' } }}>
                <Link to={`/brands/${brand.link}`}>
                  {brand.logo
                    ? <Button component="a" href={brand.logo} target="_blank" rel="noopener noreferrer" sx={{ width: '280px', height: '160px' }}>
                      <ImageLoader src={brand.logo} alt={brand.name} style={{ width: '100%', height: '100%', objectFit: 'contain' }} loading="eager"/>
                    </Button>
                    : <Button variant="text" sx={{ color: theme.palette.fontColor.main, fontSize: '34px', fontWeight: 600, width: '280px', height: '160px' }}>Altele</Button>
                  }
                </Link>
                <Divider color={theme.palette.fontColor.main} sx={{ margin: '20px 0' }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px', fontSize: '14px', fontWeight: 500 }}>
                  <Typography variant='span'>{brand.totalCount} prod.</Typography>
                  <Divider flexItem orientation='vertical' color={theme.palette.fontColor.main} />
                  <Typography variant='span'>
                    <NumericFormat value={brand.minPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} />
                    <span style={{ marginLeft: '5px' }}>-</span>
                    <span style={{ marginLeft: '5px' }}><NumericFormat value={brand.maxPrice} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={true} decimalScale={2} /></span>
                    <span style={{ marginLeft: '5px' }}>RON</span>
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </StyledBox>
    </Box>
  )
}

export default Brands
