import { FAILED_COLLECTIONS, FETCH_COLLECTIONS, LOADING_COLLECTIONS } from '../actions/collectiosActions'

export const initialState = {
  brands: { loading: true, data: [], error: null },
  categories: { loading: true, data: [], error: null }
  // Add initial state for other types as needed
}

export const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_COLLECTIONS:
      return initialState
    case FETCH_COLLECTIONS:
    {
      const newState = { ...state }
      action.payload.data.forEach(item => {
        const { type } = item // Assuming data structure needs adjusting
        if (!newState[type]) {
          newState[type] = { loading: false, data: [], error: null }
        }
        newState[type].data.push(item)
        newState[type].loading = false
      })
      return newState
    }
    case FAILED_COLLECTIONS:
      return { ...state, [action.payload.type]: { ...state[action.payload.type], loading: false, error: action.payload.error } }
    default:
      return state
  }
}
