import React from 'react'
import { Stack, Box, Typography, Divider, Grid, Pagination, Skeleton } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { theme } from '../../theme'
import Card from './Card'
import FilterDropdown from './FilterDropDown'
import PropTypes from 'prop-types'

const Products = ({ items, totalPages, pageNumber, onChangePagination, filtersData, initialFilters, loading, applyFilters }) => {
  const location = useLocation()
  function removeFirstSlash (str) {
    if (str.charAt(0) === '/') {
      return str.substring(1)
    }
    return str
  }
  function capitalizeFirstLetter (string) {
    if (string) {
      const newString = string.replaceAll('-', ' ')
      return newString.charAt(0).toUpperCase() + newString.slice(1)
    }
    return null
  }
  const [entity, pathName] = removeFirstSlash(location.pathname).split('/')
  let entityPath
  const subPath = capitalizeFirstLetter(pathName) || null
  switch (entity) {
    case 'categories':
      entityPath = pathName ? `Categorii / ${subPath}` : 'Categorii'
      break
    case 'brands':
      entityPath = pathName ? `Branduri / ${subPath}` : 'Branduri'
      break
    case 'promotii':
      entityPath = 'Promoții'
      break
    case 'lichidare':
      entityPath = 'Lichidare'
      break
    case 'pachete':
      entityPath = 'Pachete'
      break
    default:
      entityPath = 'Produse'
      break
  }
  const skeletonItemsRange = (start, end) => {
    return Array.from({ length: end - start }, (v, k) => k + start)
  }
  return (
    <Box bgcolor={theme.palette.primary.main} flex={5} p='20px 30px' border='solid 1px' borderColor={theme.palette.borderColor.main} borderRadius="4px">
       <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant='span' fontSize='18px' fontWeight={600} paddingBottom='20px'>{ entityPath }</Typography>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
              <Typography variant='span'>
                 <FilterDropdown
                    filtersData={filtersData}
                    loading={loading}
                    applyFilters={applyFilters}
                    initialFilters={initialFilters}/>
              </Typography>
            </Box>
        </Box>
        <Divider orientation='horizontal' aria-hidden="true" color={theme.palette.fontColor.main} />
        <Stack display='flex' alignItems='center' >
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          {loading
            ? skeletonItemsRange(0, 24).map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', justifyContent: 'center' }} key={index} >
                <Box sx={{ minWidth: '220px', minHeight: '450px' }}>
                  <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton animation="wave" sx={{ width: '75%' }}/>
                  <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                </Box>
              </Grid>
            ))
            : items.map((item) => (
              <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', justifyContent: 'center' }} key={item._id} >
                  <Card item={item}/>
              </Grid>
            ))
          }
            </Grid>
            <Box padding='25px 0'>
              <Pagination count={totalPages} page={pageNumber} onChange={onChangePagination} />
            </Box>
        </Stack>
      </Box>
  )
}

Products.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
      // Define other properties of an item here if necessary
    })
  ).isRequired,
  totalPages: PropTypes.number,
  pageNumber: PropTypes.number,
  onChangePagination: PropTypes.func,
  filtersData: PropTypes.object,
  initialFilters: PropTypes.object,
  applyFilters: PropTypes.func,
  loading: PropTypes.bool
}

export default Products
