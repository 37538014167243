import { userReducer } from './userReducer'
import { collectionsReducer } from './collectionsReducer'
import { itemsReducer } from './itemsReducer'
import { searchReducer } from './searchReducer'
import { favoritesReducer } from './favoritesReducer'
import { cartReducer } from './cartReducer'
import { settingsReducer } from './settingsReducer'

export const initialState = {
  settings: { loading: true, data: [], error: null },
  user: { isAuthenticated: false, data: {}, loading: true, show: false },
  favorites: { loading: false, results: [], error: null, total: 0 },
  cart: { loading: false, results: [], error: null, total_items: 0, total_price: 0, total_unique_items: 0 },
  collections: {
    brands: { loading: true, data: [], error: null },
    categories: { loading: true, data: [], error: null }
  },
  search: { loading: false, open: false, input: '', results: [], total: 0, error: null },
  items: { loading: true, results: [], total: 0, error: null }
}

export const rootReducer = ({ user, collections, items, search, favorites, cart, settings }, action) => ({
  settings: settingsReducer(settings, action),
  user: userReducer(user, action),
  collections: collectionsReducer(collections, action),
  search: searchReducer(search, action),
  items: itemsReducer(items, action),
  favorites: favoritesReducer(favorites, action),
  cart: cartReducer(cart, action)
})
