import React, { useState, useEffect, useRef } from 'react'
import { Grid } from '@mui/material'
import { StyledBox } from '../components/styledComponents/StyledItemComponents'
import Sidebar from '../components/Profile/Sidebar'
import UserInfo from '../components/Profile/UserInfo'
import ProfileForm from '../components/Profile/ProfileForm'
import PasswordResetForm from '../components/Profile/PasswordResetForm'
import { useAppContext } from '../context/AppProvider'
import { useLocation, useNavigate } from 'react-router-dom' // Import hooks

const ProfilulMeu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = useAppContext()
  const userInfoRef = useRef(null) // Ref for UserInfo component

  const [scrollToUserInfo, setScrollToUserInfo] = useState(false) // State for scrolling to UserInfo

  const getActiveFormFromURL = () => {
    const hashValue = location.hash.substring(1)
    const key = hashValue
    return key || null
  }

  const [activeForm, setActiveForm] = useState(getActiveFormFromURL())

  useEffect(() => {
    const newPathname = location.pathname
    const newHash = activeForm ? `#${activeForm}` : ''
    if (location.hash !== newHash) {
      navigate(`${newPathname}${newHash}`, { replace: true })
    }
  }, [activeForm, navigate, location.pathname, location.hash])

  useEffect(() => {
    if (scrollToUserInfo && userInfoRef.current) {
      userInfoRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      setScrollToUserInfo(false)
    }
  }, [scrollToUserInfo])

  const renderActiveForm = () => {
    switch (activeForm) {
      case 'Profile':
        return <ProfileForm userData={state.user.data} />
      case 'Password':
        return <PasswordResetForm />
      case 'UserData':
        return <UserInfo userData={state.user.data} />
      default:
        return null // No form to display
    }
  }
  const handleSidebarItemClick = (formName) => {
    setActiveForm(formName)
    if (formName === 'UserData') {
      setScrollToUserInfo(true)
    }
  }

  return (
    <StyledBox display='flex'>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Sidebar activeForm={activeForm} setActiveForm={setActiveForm} onItemClick={handleSidebarItemClick} />
        </Grid>
        <Grid item xs={12} md={4} sx={{ p: 1 }}>
          {renderActiveForm()}
        </Grid>
      </Grid>
    </StyledBox>
  )
}

export default ProfilulMeu
