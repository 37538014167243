import { ITEMS_FETCH, ITEMS_FAILED, ITEMS_LOADING } from '../actions/itemsActions'

export const initialItemsState = { loading: false, results: [], total: 0, error: null }

export const itemsReducer = (state = initialItemsState, action) => {
  switch (action.type) {
    case ITEMS_LOADING:
      return { ...state, loading: true }
    case ITEMS_FETCH:
      return { ...state, loading: false, results: action.payload.data, total: action.payload.total }
    case ITEMS_FAILED:
      return { ...state, loading: false, results: [], error: action.payload, total: 0 }
    default:
      return state
  }
}
