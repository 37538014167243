import {
  AppBar, Badge,
  Box, Menu, MenuItem, styled,
  Toolbar, Typography,
  Divider, IconButton, Avatar, Stack
} from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
// import { Link } from 'react-router-dom'
import { PublicLink as Link } from '../PublicLink'
import { ProtectedLink } from '../ProtectedLink'
import StyledNavLink from '../StyledNavlink'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import Logout from '@mui/icons-material/Logout'

import Logo from '../../icons/primusvest.png'
import Logo2 from '../../icons/milwaukee_logo.jpg'
import Searchbox from './Searchbox/Searchbox'
import HamburgerDropdown from './HamburgerDropdown'
import CategoriesDrop from './CategoriesDrop'
import NavButton from './NavButtonStyle'
import { theme } from '../../theme'
// import { useItems } from '../../hooks/useItems'
import { useAppContext } from '../../context/AppProvider'
import { useUser } from '../../hooks/useUser'
// import PropTypes from 'prop-types'
import { ImageLoader } from '../ImageLoader'

const Navbar = () => {
  const { state } = useAppContext()
  // const { fetchItemsByEntityType } = useItems()
  const { handleUserLogout } = useUser()
  const [open, setOpen] = useState(false)
  const anchorEl = useRef()
  // const handleClose = () => {
  //   anchorEl(null)
  // }
  const StyledToolbar = useMemo(() => styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '1500px',
    margin: '0 auto'
  }), [])

  const Icons = useMemo(() => styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
    color: theme.palette.fontColor.main,
    [theme.breakpoints.down('sm')]: {
      gap: '10px'
    }
  })), [])

  return (

    <AppBar
        position='sticky' elevation={1} sx={{ backgroundColor: '#E4E4E4', display: 'flex', flexDirection: 'column' }}>
        <StyledToolbar sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <Link to="/" className="logo">
                <Typography variant='h6' sx={{ width: '345px', height: '50px', display: { xs: 'none', lg: 'block' } }}>
                    <ImageLoader src={Logo} alt="Logo" style={{ width: '180px', verticalAlign: 'bottom' }} />
                    <ImageLoader src={Logo2} alt="Logo" style={{ width: '150px', marginLeft: '15px', verticalAlign: 'bottom' }} />
                </Typography>
                <Typography variant='h6' sx={{ width: '275px', height: '50px', display: { xs: 'none', mobile: 'block', lg: 'none' } }}>
                    <ImageLoader src={Logo} alt="Logo" style={{ width: '145px', verticalAlign: 'bottom' }} />
                    <ImageLoader src={Logo2} alt="Logo" style={{ width: '115px', marginLeft: '15px', verticalAlign: 'bottom' }} />
                </Typography>
                <Typography variant='h6' sx={{ display: { smallmobile: 'block', mobile: 'none' } }}>
                  <Stack>
                    <ImageLoader src={Logo2} alt="Logo" style={{ width: '100px', verticalAlign: 'bottom' }} />
                    <ImageLoader src={Logo} alt="Logo" style={{ width: '130px' }} />
                  </Stack>
                </Typography>
            </Link>

            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <Searchbox id='medium'/>
            </Box>
            <Icons>
              {!state.user.isAuthenticated
                ? <Link to="/login" aria-label='login'>
                    <IconButton sx={{ p: 0 }} aria-label='login'>
                      <PersonOutlineIcon
                        sx={{ color: theme.palette.fontColor.main, fontSize: '27px' }} />
                    </IconButton>
                </Link>
                : <Box>
                    <IconButton
                    ref={anchorEl}
                    onClick={() => setOpen(true)}
                     size="small"
                     sx={{ ml: 2, p: 0 }}
                     aria-controls={open ? 'account-menu' : undefined}
                     aria-haspopup="true"
                     aria-expanded={open ? 'true' : undefined}
                    >
                    <Avatar sx={{
                      height: '32px',
                      width: '32px',
                      fontSize: '18px',
                      bgcolor: theme.palette.primary.main,
                      color: theme.palette.fontColor.main,
                      fontWeight: 500
                    }}>{state.user.data.email[0].toUpperCase()}</Avatar>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl.current}
                    id="account-menu"
                    open={open}
                    onClose={() => setOpen(false)}
                    onClick={() => setOpen(!open)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0
                        }
                      }
                    }}
                    keepMounted
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
        <ProtectedLink to="/profilulmeu" >
          <MenuItem onClick={() => setOpen(!open) } sx={{ fontSize: '16px' }}>
            <PersonOutlineIcon sx={{ color: theme.palette.fontColor.main, fontSize: '23px', marginBottom: '2px', marginRight: '5px' }}/>
            Profilul meu
          </MenuItem>
        </ProtectedLink>
        <Divider variant="middle" component="li" sx={{ margin: '8px 0' }} />
        <MenuItem onClick={() => {
          setOpen(!open)
          handleUserLogout()
        }} sx={{ fontSize: '16px' }}>
          <Logout sx={{ color: theme.palette.fontColor.main, fontSize: '22px', marginBottom: '2px', marginRight: '5px' }} />
          Logout
        </MenuItem>
        </Menu>
      </Box>}
                  <ProtectedLink to="/favorite">
                    <IconButton sx={{ p: 0 }} aria-label="favorite">
                        <Badge badgeContent={state.favorites ? state.favorites.total : 0} color='error'>
                            <FavoriteBorderOutlinedIcon sx={{ color: theme.palette.fontColor.main }}/>
                        </Badge>
                    </IconButton>
                </ProtectedLink>
                <ProtectedLink to="/cosulmeu">
                    <IconButton sx={{ p: 0 }} aria-label="cosulmeu">
                        <Badge badgeContent={state.cart ? state.cart.total_items : 0} color='error'>
                            <ShoppingCartOutlinedIcon sx={{ color: theme.palette.fontColor.main }}/>
                        </Badge>
                    </IconButton>
                </ProtectedLink>
                <Divider orientation="vertical" variant="middle" flexItem aria-hidden="true" color={theme.palette.fontColor.main} sx={{ display: { xs: 'none', lg: 'block' } }}/>
                <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <Typography variant='h6' color={theme.palette.fontColor.main} sx={{ fontWeight: 600, fontSize: '16px', display: 'flex', justifyContent: 'center' }}>+40 770 585 736</Typography>
                    <Typography variant='h6' color={theme.palette.fontColor.main} sx={{ fontWeight: 600, fontSize: '16px', display: 'flex', justifyContent: 'center' }}>office@primusvest.ro</Typography>
                </Box>
            </Icons>
        </StyledToolbar>

       {/* box wrappes styled toolbar we can add background color on box */}
      <Box sx={{ backgroundColor: theme.palette.primary.main }}>
        <StyledToolbar variant='dense' sx={{ justifyContent: { xs: 'center', md: 'block' }, padding: { xs: '0', sm: '0', md: '5px 24px' } }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                <CategoriesDrop />
                <Divider orientation="vertical" flexItem variant="middle" color={theme.palette.fontColor.main}/>

                <StyledNavLink to="/branduri"><NavButton sx={{ transition: 'color 0.3s ease', '&:hover': { color: '#e20613' } }}>Branduri</NavButton></StyledNavLink>
                <Divider orientation="vertical" flexItem variant="middle" color={theme.palette.fontColor.main}/>

                <StyledNavLink to="/promotii"><NavButton sx={{ transition: 'color 0.3s ease', '&:hover': { color: '#e20613' } }}>Promoții</NavButton></StyledNavLink>
                <Divider orientation="vertical" flexItem variant="middle" color={theme.palette.fontColor.main}/>
                <StyledNavLink to="/pachete"><NavButton sx={{ transition: 'color 0.3s ease', '&:hover': { color: '#e20613' } }}>Pachete</NavButton></StyledNavLink>
                <Divider orientation="vertical" flexItem variant="middle" color={theme.palette.fontColor.main}/>
                <StyledNavLink to="/lichidare"><NavButton sx={{ transition: 'color 0.3s ease', '&:hover': { color: '#e20613' } }}>Lichidare</NavButton></StyledNavLink>
            </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <HamburgerDropdown />
                </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                      <Searchbox id='small'/>
              </Box>

            <StyledNavLink to="/" aria-label='home'>
                <Box sx={{ width: '158px', display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                    <HomeOutlinedIcon sx={{ fontSize: '35px', color: '#313131', display: { xs: 'none', md: 'flex' } }} />
                </Box>
            </StyledNavLink>

        </StyledToolbar>
    </Box>
    </AppBar>

  )
}

export default Navbar
