export const parseEmail = (email) => {
  const atIndex = email.indexOf('@')
  if (atIndex === -1) return "Email must contain '@'."

  const localPart = email.substring(0, atIndex)
  const domainPart = email.substring(atIndex + 1)
  if (!localPart) return 'Email local part (before @) is missing.'
  if (!domainPart) return 'Email domain part (after @) is missing.'
  if (domainPart.indexOf('.') === -1) return "Email domain must contain a '.'."

  const domainsWithSubaliasing = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'protonmail.com']
  if (domainsWithSubaliasing.includes(domainPart) && localPart.includes('+')) {
    return "Sub-aliasing with '+' is not allowed for this email domain."
  }

  // Further checks can be added here, such as length or specific character validations

  return true
}
