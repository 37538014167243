import React from 'react'
import { StyledBox } from '../components/styledComponents/StyledItemComponents'
import { Box } from '@mui/material'
import { theme } from '../theme'

const Gdpr = () => {
  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main, paddingTop: '30px' }}>
    <StyledBox sx={{ padding: '50px 70px', backgroundColor: theme.palette.primary.main, textAlign: 'justify' }}>
      <h4>GDPR</h4>

      <h5>POLITICA PRIVIND CONFIDENȚIALITATEA DATELOR PERSONALE</h5>
      <br />

      <p>Protecția datelor dvs. private este importantă pentru noi. Mai jos veți găsi informații detaliate despre modul în care folosim datele dvs.</p>

      <h5>Numele și datele de contact ale operatorului de date (astfel cum sunt definite în legislația privind protecția datelor)</h5>

      <p>
        SC PRIMUSVEST SRL <br />
        Cod Unic de Înregistrare: 35750706 <br />
        Nr. Înmatriculare: J05/485/2016 <br />
        Sediul: Jud. Bihor, Sat Sârbeşti, Com. Lunca, Sârbeşti, Nr. 72 <br />
        Email: office@primusvest.ro <br />
        Telefon: +40 770 585 736
      </p>

      <h5>Datele cu caracter personal pe care le prelucrăm</h5>

      <p>Tipul de date cu caracter personal – datele cu caracter personal ce vor fi prelucrate de catre PRIMUSVEST SRL sunt date precum: e-mail, număr de telefon.</p>

      <p>În general, nu colectăm informații sensibile, cu excepția cazului în care acestea sunt oferite voluntar de dvs. sau dacă suntem obligați să facem acest lucru în conformitate cu legile sau reglementările aplicabile. Putem folosi datele privind sănătatea furnizate de dumneavoastră pentru a vă servi mai bine și a vă îndeplini nevoile speciale.</p>

      <h5>Cum obținem datele cu caracter personal?</h5>

      <p>Putem colecta datele dvs. personale în general în următoarele situații:</p>

      <ul>
        <li>În mod direct, atunci când ni le comunicați fizic, în magazin</li>
        <li>Prin aplicarea pentru un post vacant din cadrul companiei noastre</li>
        <li>Prin înregistrarea pe care o efectuați pe site-ul nostru</li>
      </ul>

      <h5>Scopul colectării, prelucrării și utilizării datelor dvs. cu caracter personal</h5>

      <p>Oricare dintre datele dvs. cu caracter personal care ne sunt furnizate prin intermediul metodelor descrise mai sus sunt tratate de noi în conformitate cu legislația relevantă privind protecția datelor cu caracter personal; acestea vor fi folosite în mod confidențial de către noi în orice moment și vor fi colectate, prelucrate și utilizate numai în următoarele scopuri:</p>

      <ul>
        <li>(a) pentru a asigura funcționarea corectă a site-ului,</li>
        <li>(b) Protejarea propriilor noastre interese legitime de afaceri în ceea ce privește furnizarea de consultanță și asistență pentru clienții noștri în alegerea și utilizarea echipamentelor și materialelor, precum și asigurarea unui design personalizat pentru proiectele de construcție și amenajare.</li>
      </ul>

      <p>În plus față de cele de mai sus, orice date pe care le transmiteți prin intermediul procedurii de cerere de comandă vor fi utilizate de noi numai pentru a finaliza și procesa comanda dvs. de achiziție.</p>

      <h5>Temeiul juridic pentru prelucrarea datelor cu caracter personal</h5>

      <p>Prelucrarea datelor are loc în conformitate cu Regulamentul general al UE privind protecția datelor (denumit în continuare: „GDPR”) și pe baza următoarelor principii juridice:</p>

      <ul>
        <li>În cazurile în care obținem acordul dvs. pentru prelucrarea tranzacțiilor cu privire la datele dvs. cu caracter personal, prelucrarea datelor se bazează pe articolul GDPR 6 (1a).</li>
        <li>Toate datele pe care le partajați cu noi în contextul comenzilor de achiziție și care sunt necesare pentru îndeplinirea și/sau administrarea acestor comenzi vor fi prelucrate de noi în conformitate cu articolul GDPR 6 (1b).</li>
        <li>Dacă prelucrarea datelor este necesară pentru a putea îndeplini anumite obligații legale, atunci această prelucrare a datelor se bazează pe articolul GDPR 6 (1c).</li>
        <li>În cazurile în care prelucrarea datelor este impusă de interesele vitale ale persoanei vizate și/sau ale unei alte persoane fizice, prelucrarea datelor va avea loc în conformitate cu articolul GDPR 6 (1d).</li>
        <li>Dacă prelucrarea datelor este necesară pentru a proteja interesele legitime ale companiei noastre sau ale unei terțe părți și dacă acestea nu sunt în conflict cu interesele primordiale, drepturile fundamentale și/sau libertățile fundamentale ale persoanei vizate, prelucrarea datelor se bazează pe articolul GDPR 6 1f).</li>
      </ul>

      <h5>Perioada de păstrare, ștergerea datelor și blocarea datelor</h5>

      <p>După terminarea comenzii de achiziție, datele dvs. vor fi blocate pentru utilizare ulterioară și vor fi șterse după perioadele de păstrare stabilite în conformitate cu legislația fiscală și comercială, cu excepția cazului în care v-ați dat în mod expres consimțământul cu privire la utilizarea ulterioară a datelor dvs.</p>

      <p>În plus, datele dvs. vor fi șterse întotdeauna de îndată ce consimțământul dvs. nu mai este disponibil sau imediat ce acestea nu mai sunt necesare în scopul prelucrării datelor. Dacă datele dvs. nu mai sunt necesare în acest scop și/sau consimțământul dvs. nu mai este disponibil, eliminând astfel temeiul juridic, dar dacă datele dvs. trebuie totuși stocate în continuare, datorită obligațiilor legale, oficiale sau contractuale existente (de ex. angajamente de garanție sau de contabilitate financiară), prelucrarea datelor va fi restricționată prin marcarea și blocarea acestor date.</p>

      <h5>Fișierele jurnal</h5>

      <p>Ori de câte ori ne vizitați site-ul, datele de utilizare sunt transmise de browserul dvs. de internet și stocate în fișierele jurnal de server. Înregistrările stocate în acest mod conțin următoarele date: data și ora apelului, denumirea site-ului apelat, adresa IP, adresa URL sursă de la care ați ajuns pe site-ul nostru, tipul și versiunea browserului web pe care l-ați folosit, sistemul de operare utilizat.</p>

      <p>Aceste date vor fi utilizate exclusiv în scopul comunicării tehnice și pentru a asigura funcționarea tehnică; după terminarea utilizării site-ului nostru, acestea vor fi șterse, cu condiția respectării eventualelor obligații legale de păstrare. Bazele juridice sunt îndeplinirea obligațiilor legale privind securitatea datelor, interesul nostru legitim față de funcționarea tehnică a site-ului nostru, eliminarea defecțiunilor și garantarea securității.</p>

      <h5>Partajarea datelor cu caracter personal în contextul procesării comenzilor</h5>

      <p>Dacă astfel de schimburi de date sunt necesare pentru livrarea bunurilor, datele dvs. sunt transmise companiei de transport relevante care a fost indicată.</p>

      <p>Baza legală pentru prelucrarea datelor este scopul executării unui contract (adică procesarea comenzilor).</p>

      <h5>Securitatea datelor</h5>

      <p>Ca parte a procesului de comandă, datele dvs. cu caracter personal sunt criptate și transmise prin internet. Punem în aplicare măsurile tehnice și organizaționale recunoscute în general pentru a asigura site-ul nostru web și alte sisteme împotriva pierderii, distrugerii, accesării, modificării și distribuției datelor dvs. de către persoane neautorizate, dar acest lucru nu se poate face fără a putea fi garantată o protecție totală.</p>

      <p>Accesul la contul dvs. de client este posibil numai prin introducerea parolei personale. Păstrați întotdeauna datele de acces confidențiale și închideți fereastra browserului când ați terminat sesiunea, mai ales dacă folosiți computerul în comun cu alte persoane.</p>

      <h5>Drepturile persoanelor vizate</h5>

      <p>În calitate de persoană vizată, aveți următoarele drepturi:</p>

      <ul>
        <li><strong>Dreptul de acces</strong>: Aveți dreptul să obțineți informații privind datele cu caracter personal pe care le-am păstrat despre dvs., durata stocării, prelucrarea datelor și orice destinatari ai acestora. De asemenea, aveți dreptul să primiți o copie a datelor cu caracter personal care fac obiectul prelucrării datelor.</li>
        <li><strong>Dreptul de rectificare</strong>: Aveți dreptul să solicitați remedierea oricăror date cu caracter personal incorecte și completarea datelor cu caracter personal incomplete.</li>
        <li><strong>Dreptul de ștergere („dreptul de a fi uitat”)</strong>: Cu condiția existenței unor cerințe legale corespunzătoare, aveți dreptul să solicitați ștergerea datelor dvs. cu caracter personal. Dacă ștergerea este împiedicată de obligațiile de păstrare statutare, prelucrarea datelor este restricționată în consecință (a se vedea mai jos).</li>
        <li><strong>Dreptul la restricționarea prelucrării</strong>: Cu condiția existenței cerințelor legale corespunzătoare, aveți dreptul să cereți restricționarea prelucrării datelor dvs. cu caracter personal, adică datele dvs. să fie marcate în așa fel încât să fie restricționate în viitor (și astfel blocate).</li>
        <li><strong>Dreptul la portabilitatea datelor</strong>: Cu condiția existenței cerințelor legale corespunzătoare, aveți dreptul de a ne cere trimiterea datelor dvs. fie către dvs., fie către un operator de date numit de dvs., caz în care le vom trimite conform cu instrucțiunile într-un format standard, structurat și electronic.</li>
        <li><strong>Dreptul de a se opune marketingului direct</strong>: Sunteți îndreptățit în orice moment să vă opuneți prelucrării datelor dvs. cu caracter personal în scopuri de marketing („obiecție față de marketing”).</li>
        <li><strong>Dreptul de a vă opune prelucrării datelor în cazul în care temeiul juridic este un „interes legitim”</strong>: Aveți dreptul să vă opuneți prelucrării datelor dvs. în orice moment, cu condiția ca o astfel de obiecție să aibă temeiul legal într-un „interes legitim”. Într-un astfel de caz, vom întrerupe prelucrarea datelor dvs., cu excepția cazului în care putem - în limitele legii - să demonstrăm motive legitime convingătoare pentru o prelucrare ulterioară, depășind drepturile dvs.</li>
        <li><strong>Dreptul de a vă revoca acordul</strong>: Dacă ne-ați dat acordul cu privire la colectarea și prelucrarea datelor dvs., puteți să îl revocați în orice moment cu efect în viitor. Cu toate acestea, acest lucru nu afectează legitimitatea prelucrării datelor dvs. până la data revocării.</li>
        <li><strong>Dreptul de a depune o plângere la autoritatea de supraveghere</strong>: Puteți depune o plângere la autoritatea de supraveghere competentă dacă credeți că prelucrarea datelor dvs. a încălcat legea aplicabilă. Pentru a face acest lucru, puteți contacta autoritatea responsabilă cu protecția datelor de la locul dvs. de reședință sau din statul federal în care vă aflați sau la autoritatea noastră responsabilă cu protecția datelor.</li>
      </ul>

      <h5>Modalitate de contact</h5>

      <p>Ne puteți contacta direct dacă aveți întrebări cu privire la colectarea, prelucrarea sau utilizarea datelor dvs. cu caracter personal sau în legătură cu informațiile, rectificarea, blocarea sau ștergerea datelor sau despre revocarea oricărui consimțământ pe care l-ați dat.</p>

      <p>Datele noastre de contact sunt următoarele:</p>

      <p>
        SC PRIMUSVEST SRL <br />
        Cod Unic de Înregistrare: 35750706 <br />
        Nr. Înmatriculare: J05/485/2016 <br />
        Sediul: Jud. Bihor, Sat Sârbeşti, Com. Lunca, Sârbeşti, Nr. 72 <br />
        Email: office@primusvest.ro <br />
        Telefon: +40 770 585 736
      </p>
      <p>Ultima actualizare a politicii de confidențialitate: Aprilie 2024</p>
</StyledBox>
</Box>
  )
}

export default Gdpr
