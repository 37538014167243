import React, { useEffect, useContext } from 'react'
import { AppContext } from '../context/AppProvider' // Adjust the import to your context
import { hideLoginModal } from '../actions/userActions'
import { Outlet } from 'react-router-dom'

export const NonProtectedRouteWrapper = () => {
  const { state, dispatch } = useContext(AppContext)

  useEffect(() => {
    if (state.user.show) {
      dispatch(hideLoginModal())
    }

    // return () => dispatch(hideLoginModal())
  }, [hideLoginModal])

  return <Outlet />
}
