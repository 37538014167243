import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: '#E4E4E4'
    },
    accent: {
      main: '#e20613',
      light: '#eb515a'
    },
    fontColor: {
      main: '#313131',
      medium: '#616161',
      light: '#919191',
      dark: '#1D1D1B'
    },
    borderColor: {
      main: '#31313140'
    },
    success: {
      main: '#00AA00'
    },
    warning: {
      main: '#ffcc00'
    }
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      smallmobile: 0,
      mobile: 450,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      smalldesktop: 1353
    }
  }
})
