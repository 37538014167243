import React, { useState, useRef, useCallback } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { Button, ButtonGroup, Stack, Skeleton } from '@mui/material'
import { DiscountLabel, ProductName, BrandName, StockLabel } from '../styledComponents/StyledItemComponents'
import { Currency } from 'react-intl-number-format'
import StyledLink from '../StyledLink'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAppContext } from '../../context/AppProvider'
import PropTypes from 'prop-types'
import { useCart } from '../../hooks/useCart'
import { debounce } from 'lodash'
import { ImageLoader } from '../ImageLoader'

export default function MediaControlCard ({ item }) {
  const itemData = useRef(item)
  const { state } = useAppContext()
  const theme = useTheme()
  const [counter, setCounter] = useState(item.count)
  const { removeItemFromCart, updateCartItem } = useCart()

  const debounceUpdateCartItem = useCallback(debounce((item, count) => {
    updateCartItem(item, count)
  }, 500), [])

  return (
    <Card
    sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'space-between' }, width: { xs: '250px', sm: 'auto' }, height: { xs: '450px', sm: '250px', md: '150px' }, marginTop: '20px', padding: '0 30px', flexDirection: { xs: 'column', sm: 'row' } }}>

        <Box display='flex' sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        { state.cart.loading
          ? (
                <Box sx={{ minWidth: '220px', minHeight: '450px' }}>
                  <Skeleton variant="rectangular" width={210} height={118} />
                  <Box>
                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                    <Skeleton animation="wave" sx={{ width: '75%' }}/>
                    <Skeleton animation="wave" sx={{ width: '75%' }}/>
                    <Skeleton variant="text" sx={{ width: '50%', fontSize: '22px' }} />
                  </Box>
                </Box>
            )
          : (
          <>
            <Box sx={{ position: 'relative' }}>
            {item.discount.type && (
              <DiscountLabel sx={{ position: 'absolute' }}>
                -{ item.discount.value }{item.discount.type === 'percent' ? '%' : ' RON' }
              </DiscountLabel>
            )}
              <CardMedia
                component="img"
                sx={{ height: '100%', width: '150px', objectFit: 'contain' }}
                image={item.cover}
                alt="Product image"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1' }}>
              {item && (
              <StyledLink to={{ pathname: `/items/${item._id}` }} >

              <ProductName sx={{ fontSize: '14px', WebkitLineClamp: 2, textOverflow: 'ellipsis' }}>
                {item.name}
              </ProductName>
              </StyledLink>
              )}
              <Typography style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                { !item.brand?.logo
                  ? <BrandName>{item.brand.name}</BrandName>
                  : <ImageLoader src={item.brand.logo} alt={item.brand.name} style={{ height: '18px', marginRight: '5px', marginBottom: '5px', objectFit: 'contain' }}/>
                }
              </Typography>
              <StockLabel sx={{
                position: 'relative',
                left: '0px',
                paddingLeft: '0',
                color: item.stock >= 3
                  ? theme.palette.success.main
                  : item.stock > 0 && item.stock < 3
                    ? theme.palette.warning.main
                    : theme.palette.accent.main
              }}>
                  {item.stock >= 3
                    ? 'În stoc!'
                    : item.stock > 0 && item.stock < 3
                      ? 'Stoc limitat!'
                      : 'Ultimul în stoc!'}
                </StockLabel>
              </CardContent>
              </Box>
            </>)}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* <Divider orientation='vertical' aria-hidden="true" color={theme.palette.fontColor.main} /> */}
          <Stack sx={{ padding: '15px' }}>
          { item.discount?.type
            ? <Box sx={{ alignItems: 'center' }}>
          <Typography variant='h6' sx={{ color: theme.palette.accent.main, fontSize: '16px', paddingRight: '20px', fontWeight: 400 }}>
            <Currency locale="ro-RO" currency="RON">
            {item.discount.price * counter}
            </Currency>
          </Typography>
          <Typography variant='h6' sx={{ textDecoration: 'line-through', fontSize: '12px', paddingLeft: '2px', fontWeight: 400 }}>
            <Currency locale="ro-RO" currency="RON">
            { item.price * counter}
            </Currency>
            </Typography>
          </Box>
            : <Box sx={{ alignItems: 'center' }}>
          <Typography variant='span' sx={{ color: theme.palette.accent.main, fontSize: '16px', paddingRight: '20px' }}>
            <Currency locale="ro-RO" currency="RON">
            {item.price * counter}
            </Currency>
          </Typography>
          </Box>
        }

          <ButtonGroup size="small" aria-label="small outlined button group">
          <Button
                variant='outlined'
              disabled={counter <= 0}
              onClick={() => {
                setCounter((counter) => {
                  const newCount = counter - 1
                  if (newCount === 0) {
                    removeItemFromCart(itemData)
                  } else {
                    debounceUpdateCartItem(itemData, { count: newCount })
                  }
                  return newCount
                })
              }}
              color='fontColor'
            >
              -
            </Button>
            <Button variant='outlined' disabled color='fontColor'>
              {counter}
            </Button>
            <Button
                variant='outlined'
                disabled={counter >= item.stock}
                onClick={() => {
                  setCounter((counter) => {
                    const newCount = counter + 1
                    debounceUpdateCartItem(itemData, { count: newCount })
                    return newCount
                  })
                }}
                color='fontColor'
            >
              +
            </Button>
        </ButtonGroup>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    variant="outlined"
                    size='small'
                    onClick={() => removeItemFromCart(itemData)}
                    startIcon={<DeleteIcon />}
                    color="success"
                    sx={{ color: 'success', borderRadius: '16px', height: 'auto', marginTop: '10px' }}
                    >
                  Șterge
                </Button>
              </Box>
              </Stack>
      </Box>
    </Card>
  )
}

MediaControlCard.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object, PropTypes.number])
}
