export const parseCUI = (cui) => {
  let cleanCUI = cui
  let isValidPrefix = false

  if (cui.startsWith('RO')) {
    cleanCUI = cui.substring(2) // Remove "RO" prefix
    isValidPrefix = true
  } else if (cui[0] >= '0' && cui[0] <= '9') {
    isValidPrefix = true
  }

  if (!isValidPrefix) return "CUI must start with 'RO' or a digit."

  if (cleanCUI.length !== 8) return 'The CUI must consist of 8 digits.'

  for (const char of cleanCUI) {
    if (char < '0' || char > '9') return 'The CUI must contain only digits.'
  }

  return true
}
