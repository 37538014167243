import React from 'react'
import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useAppContext } from '../context/AppProvider' // Adjust the import path as necessary
import LoginForm from './Forms/LoginForm' // Your login form
import { useNavigate, useLocation } from 'react-router-dom'
import { theme } from '../theme'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: theme.palette.primary.main
  // bgcolor: 'background.paper',
  // boxShadow: 24,
  // p: 4
}

const LoginModal = () => {
  const { state, dispatch } = useAppContext()
  const navigate = useNavigate()
  const location = useLocation()

  const isProtectedRoute = () => {
    // List of your protected routes
    const protectedRoutes = ['/favorite', '/cosulmeu', '/profilulmeu']
    return protectedRoutes.includes(location.pathname)
  }

  const handleClose = () => {
    dispatch({ type: 'HIDE_LOGIN_MODAL' })
    if (isProtectedRoute() && !state.user.isAuthenticated) {
      navigate('/')
    }
  }

  return (
    <Modal
      open={state.user.show}
      onClose={handleClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
    >
      <Paper elevation={0} sx={style}>
        <Box position="absolute" top={0} right={0}>
          <IconButton aria-label='login-modal' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <LoginForm />
      </Paper>
    </Modal>
  )
}

export default LoginModal
