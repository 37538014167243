import { React, useEffect, useLayoutEffect } from 'react'
import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import MUIHeader from './components/Header/MUIHeader'
import Footer from './components/Footer/Footer'

import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import Parolanoua from './pages/Parolanoua'
import Favorite from './pages/Favorite'
import Cosulmeu from './pages/Cosulmeu'
import Gdpr from './pages/Gdpr'
import Termeni from './pages/Termeni'
import Items from './pages/Items'
import Item from './pages/Item'
import Brands from './pages/Brands'
// import Pachete from './pages/Pachete'
import NotFoundPage from './pages/NotFound'
import Profilulmeu from './pages/Profilulmeu'
import Datelegale from './pages/Datelegale'

import LoginModal from './components/LoginModal'

import { useAppContext } from './context/AppProvider' // This is the new import
import { useCollections } from './hooks/useCollections' // Ensure this path is correct
import { ProtectedRoute } from './components/ProtectedRoute' // Adjust the import path as necessary
import { NonProtectedRouteWrapper } from './components/NonProtectedRoute' // Adjust the import path as necessary
import { useScript } from './hooks/useTurnstile'
import { SCRIPT_URL } from '@marsidev/react-turnstile'
import { useUser } from './hooks/useUser'
import { useSettings } from './hooks/useSettings'

const App = () => {
  const { loaded, error } = useScript(SCRIPT_URL)

  const location = useLocation()
  const { state } = useAppContext()
  const { fetchCollections } = useCollections()
  const { fetchSettings } = useSettings()
  const { handleUserAuthentication, handleUserLogout } = useUser()

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (token) {
      handleUserAuthentication(token).catch(console.error)
    } else {
      handleUserLogout()
    }
  }, [])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'token' && !event.newValue) {
        // Token has been removed, log out the user
        handleUserLogout()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    fetchCollections()
  }, [fetchCollections])

  useEffect(() => {
    fetchSettings()
  }, [fetchSettings])

  const excludeHeader = location.pathname === '/login' || location.pathname === '/registrare'

  if (!loaded) return <div>Loading...</div>
  if (error) return <div>Error loading script</div>

  return (
    <div className='App'>
    { !excludeHeader && <MUIHeader /> }
    {state.user.show && <LoginModal />}
    <Routes>
      <Route element={<NonProtectedRouteWrapper />}>
        <Route path="/login" element={!state.user.isAuthenticated ? <Login /> : <Navigate to="/" />} />
        <Route path="/registrare" element={!state.user.isAuthenticated ? <Register /> : <Navigate to="/" />} />
        <Route path="/parolanoua" element={!state.user.isAuthenticated ? <Parolanoua /> : <Navigate to="/" />} />

        <Route path="/branduri" element={<Brands />} />
        <Route path='brands/:brandLink' element={<Items />} />
        <Route path='collections' element={<Items />} />
        <Route path='categories/:categoryLink' element={<Items />} />
        <Route path='items/:itemId' element={<Item />} />

        <Route path="/pachete" element={<Items />} />
        {/* <Route path='pachete/:packageLink' element={<Items />} />  NEED A SPECIAL PAGE FOR PACKAGE ITEMS */}

        <Route path="/promotii" element={<Items />} />
        <Route path="/lichidare" element={<Items />} />
        <Route path="/gdpr" element={<Gdpr />} />
        <Route path="/termeni" element={<Termeni />} />
        <Route path="/datelegale" element={<Datelegale />} />
        <Route path="/" element={<Home />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route path="/favorite" element={<Favorite />} />
        <Route path="/cosulmeu" element={<Cosulmeu />} />
        <Route path="/profilulmeu" element={<Profilulmeu />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    <Footer />
    </div>
  )
}

export default App
