import { Button, styled } from '@mui/material'

const NavButton = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  margin: '0 10px',
  color: theme.palette.fontColor.main,
  [theme.breakpoints.down('md')]: {
    textTransform: 'capitalize',
    fontWeight: 500

  }

}))

export default NavButton
