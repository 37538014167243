import { LOGIN_SUCCESS, LOGOUT, HIDE_LOGIN_MODAL, SHOW_LOGIN_MODAL, SET_AUTHENTICATED, UPDATE_PROFILE } from '../actions/userActions'

export const initialUserState = { isAuthenticated: false, data: {}, loading: true, show: false }

export function userReducer (state = initialUserState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, isAuthenticated: true, data: action.payload, loading: false }
    case LOGOUT:
      return { ...state, isAuthenticated: false, data: {}, loading: false }
    case SHOW_LOGIN_MODAL:
      return { ...state, show: true }
    case HIDE_LOGIN_MODAL:
      return { ...state, show: false }
    case SET_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload, loading: false }
    case UPDATE_PROFILE:
      return { ...state, data: { ...state.data, ...action.payload }, loading: false }
    default:
      return state
  }
}
