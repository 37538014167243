import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { theme } from '../../theme.js'

const DeleteAccountDialog = ({ open, onClose, onConfirm }) => {
  return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{'Confirmare'}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Ești sigur că vrei să ștergi contul? Această acțiune este ireversibilă.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button
                    variant="contained" // Changed to 'contained' to apply background color
                    size="small"
                    onClick={onClose}
                    sx={{
                      borderRadius: '21px',
                      marginTop: '15px',
                      backgroundColor: theme.palette.primary.main, // Set background color
                      color: theme.palette.primary.contrastText, // Ensure text color contrasts with the background
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark // Optional: Darken on hover for visual feedback
                      }
                    }}
                >
                    Nu
                </Button>
                <Button
                    variant="contained" // Changed to 'contained' for background color
                    size="small"
                    onClick={() => { onConfirm(); onClose() }}
                    autoFocus
                    sx={{
                      borderRadius: '21px',
                      marginTop: '15px',
                      backgroundColor: theme.palette.primary.main, // Set background color
                      color: theme.palette.primary.contrastText, // Set text color for readability
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark // Optional: Darken on hover
                      }
                    }}
                >
                    Da
                </Button>
            </DialogActions>
        </Dialog>
  )
}
DeleteAccountDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}
export default DeleteAccountDialog
