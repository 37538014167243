import { useCallback, useContext, useRef, useEffect } from 'react'
import { AppContext } from '../context/AppProvider'
import { updateCart, removeFromCart, cartLodingFetch, cartSuccessFetch, cartFailedFetch, cartReset } from '../actions/cartActions'
import { jwtDecode } from 'jwt-decode'

export const useCart = () => {
  const { state, dispatch } = useContext(AppContext)
  const apiUrl = 'https://api.primusvest.ro/v1/cart'
  const stateRef = useRef(state)
  useEffect(() => {
    stateRef.current = state
  }, [state])

  const getCartItems = useCallback(async (page) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      dispatch(cartLodingFetch())
      try {
        const userData = jwtDecode(token)
        let url = `${apiUrl}/${userData.cart_id}`
        if (page) {
          url += `?page=${page}`
        }
        const response = await fetch(`${url}`, {
          headers: { ...headers, Authorization: `${token}` }
        })
        const data = await response.json()
        dispatch(cartSuccessFetch(data))
      } catch (error) {
        dispatch(cartFailedFetch(error))
      }
    }
  }, [dispatch])

  const updateCartItem = useCallback(async (currentItemId, count) => {
    dispatch({ type: 'ATTEMPT_NAVIGATE_PROTECTED_ROUTE' })
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      const item = currentItemId.current ? currentItemId.current : currentItemId.data
      const cartId = state.user.data.cart
      const cartItem = state.cart.results?.filter(citem => citem._id === item._id)
      const itemCount = count || { count: cartItem[0]?.count + 1 || 1 }
      const data = { itemId: item._id, ...itemCount }
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(
          `${apiUrl}/${cartId}`, {
            method: 'PUT',
            headers,
            body: JSON.stringify({ items: [data] })
          }
        )
        const { message } = await response.json()
        if (message !== 'Unauthorized') {
          dispatch(updateCart(item, itemCount))
        }
      } catch (error) {
        console.error('Failed to add item to cart:', error)
        dispatch(cartFailedFetch(error))
      }
    }
  }, [dispatch])

  const removeItemFromCart = useCallback(async (currentItemId) => {
    dispatch({ type: 'ATTEMPT_NAVIGATE_PROTECTED_ROUTE' })
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }
    if (token) {
      const itemId = currentItemId.current ? currentItemId.current._id : currentItemId.data._id
      const cartId = state.user.data.cart
      headers = { ...headers, Authorization: `${token}` }
      try {
        const response = await fetch(
          `${apiUrl}/${cartId}`, {
            method: 'DELETE',
            headers,
            body: JSON.stringify({ itemId })
          }
        )
        const { message } = await response.json()
        if (message !== 'Unauthorized') {
          dispatch(removeFromCart(itemId))
        }
        return { data: message, error: null }
      } catch (error) {
        dispatch(cartFailedFetch(error))
        console.error('Failed to fetch filters:', error)
      }
    }
  }, [dispatch])

  const resetCartState = useCallback(async () => {
    dispatch(cartReset())
  }, [dispatch])

  const sendOrder = useCallback(async (data) => {
    const token = localStorage.getItem('token')
    let headers = { 'Content-Type': 'application/json' }

    dispatch({ type: 'ATTEMPT_NAVIGATE_PROTECTED_ROUTE' })
    if (token) {
      headers = { ...headers, Authorization: `${token}` }
      const cartId = state.user.data.cart
      try {
        const response = await fetch(
          `${apiUrl}/${cartId}`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ ...data })
          }
        )
        const { message, statusCode } = await response.json()
        if (statusCode === 200) {
          resetCartState()
        }
        return { message, statusCode, error: null }
      } catch (error) {
        dispatch(cartFailedFetch(error))
        console.error('Failed to fetch filters:', error)
        return { message: null, statusCode: 500, error }
      }
    }
  }, [dispatch])

  return { updateCartItem, removeItemFromCart, getCartItems, resetCartState, sendOrder }
}
