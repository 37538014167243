export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL'
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'

export const showLoginModal = () => ({
  type: SHOW_LOGIN_MODAL
})

export const hideLoginModal = () => ({
  type: HIDE_LOGIN_MODAL
})

export const loginUserAction = (token) => ({
  type: LOGIN_SUCCESS,
  payload: token
})

export const loginUserFailedAction = (error) => ({
  type: LOGIN_FAILED,
  payload: error
})

export const logoutUserAction = () => ({
  type: LOGOUT,
  payload: {}
})

export const setAuthenticated = () => ({
  type: SET_AUTHENTICATED,
  payload: {}
})

export const updateUserProfileAction = (data) => ({
  type: UPDATE_PROFILE,
  payload: data
})
