export const parsePassword = (password) => {
  let hasUpperCase = false
  let hasLowerCase = false
  let hasDigit = false
  let hasSpecialChar = false
  const minLength = 8
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/

  if (password.length < minLength) {
    return `Password must be at least ${minLength} characters long.`
  }

  for (const char of password) {
    if (char >= 'A' && char <= 'Z') hasUpperCase = true
    else if (char >= 'a' && char <= 'z') hasLowerCase = true
    else if (char >= '0' && char <= '9') hasDigit = true
    else if (specialChars.test(char)) hasSpecialChar = true
  }

  // After checking all characters, determine which criteria are not met
  const missingCriteria = []
  if (!hasUpperCase) missingCriteria.push('one uppercase letter')
  if (!hasLowerCase) missingCriteria.push('one lowercase letter')
  if (!hasDigit) missingCriteria.push('one digit')
  if (!hasSpecialChar) missingCriteria.push('one special character')

  if (missingCriteria.length === 0) {
    return true // All criteria met
  } else {
    // Join the missing criteria into a readable sentence
    const formattedCriteria = missingCriteria.join(', ').replace(/, ([^,]*)$/, ', and $1')
    return `Password must include at least ${formattedCriteria}.`
  }
}
