import React from 'react'
import StyledLink from './StyledLink'
import { useAppContext } from '../context/AppProvider'
import PropTypes from 'prop-types'

export const PublicLink = ({ to, children }) => {
  const { dispatch } = useAppContext()

  const handleClick = (e) => {
    dispatch({ type: 'NAVIGATE_PUBLIC_ROUTE', payload: { to } })
  }

  return <StyledLink to={to} onClick={handleClick}>{children}</StyledLink>
}
PublicLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}
