import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useAppContext } from '../context/AppProvider'
import { showLoginModal, hideLoginModal } from '../actions/userActions'

export const ProtectedRoute = () => {
  const { state, dispatch } = useAppContext()

  useEffect(() => {
    // Directly reacting to state changes to manage modal visibility
    if (!state.user.loading) {
      if (!state.user.isAuthenticated) {
        dispatch(showLoginModal())
      } else if (state.user.show) {
        dispatch(hideLoginModal())
      }
    }
  }, [state.user.isAuthenticated, state.user.loading, state.user.show])

  if (state.user.loading) {
    return null // Optionally, render a loading indicator.
  }

  return <Outlet />
}
