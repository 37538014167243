import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
// import { Link } from 'react-router-dom'
import { PublicLink as Link } from '../components/PublicLink'
import { theme } from '../theme'
import Logo from '../icons/primusvest.png'
import RegisterForm from '../components/Forms/RegisterForm'
import { ImageLoader } from '../components/ImageLoader'

const Register = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', padding: '40px 0', bgcolor: theme.palette.secondary.main }}>
      <Stack spacing={1}>
       <Link to="/" className="logo">
                <Typography variant='h6' sx={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                <ImageLoader src={Logo} alt="Logo" style={{ width: '200px' }} loading="eager"/>
                </Typography>
        </Link>
        <RegisterForm />
        </Stack>
    </Box>
  )
}

export default Register
