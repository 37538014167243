import { Box, Stack } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import Filter from '../components/Items/Filter'
import Products from '../components/Items/Products'
import { StyledBox } from '../components/styledComponents/StyledItemComponents'
import { theme } from '../theme'
import { useItems } from '../hooks/useItems'
import { useAppContext } from '../context/AppProvider'
import { useFilters } from '../hooks/useFilters'

const Items = () => {
  const { state } = useAppContext()
  const { fetchItemsByEntityType } = useItems()
  const location = useLocation()
  const params = useParams()
  const [page, setPage] = useState(1)
  const [fetchAttempted, setFetchAttempted] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterProps, setFilterProps] = useState(null)
  const [filters, setFilters] = useState({
    brands: [],
    categories: []
  })

  const { fetchFilters } = useFilters()
  // const navigate = useNavigate()
  // const newSelections = new Set(selectedFilters)
  const maxResult = 24
  const totalPages = state.items.total ? Math.ceil(state.items.total / maxResult) : 0

  const entityTypeMap = {
    '/categories/': 'categories',
    '/brands/': 'brands',
    '/collections': 'search',
    '/promotii': 'discount',
    '/pachete': 'package',
    '/lichidare': 'clearence'
  }

  const getEntityTypeFromPath = () => {
    if (entityTypeMap[location.pathname]) {
      return entityTypeMap[location.pathname]
    }
    for (const path in entityTypeMap) {
      if (location.pathname.includes(path)) {
        return entityTypeMap[path]
      }
    }
    return 'defaultType'
  }

  const entityType = getEntityTypeFromPath()
  const pathName = location.pathname.split('/').pop()
  const entityValue = params.brandLink || params.categoryLink || params.packageLink || pathName
  const entityData = state.collections[entityType]?.data?.find(item => item.link === entityValue || item.link === entityValue)
  const prevEntityType = useRef(entityType)

  useEffect(() => {
    if (entityData !== undefined) {
      fetchFilters(entityData.filters).then((data) =>
        setFilterProps(data)
      )
    } else {
      const defaultEntityData = { filters: ['brands', 'categories'] }
      fetchFilters(defaultEntityData.filters).then((data) =>
        setFilterProps(data))
    }
  }, [entityData])

  useEffect(() => {
    const initialFilters = {
      brands: searchParams.get('brands') ? searchParams.get('brands').split(',') : [],
      categories: searchParams.get('categories') ? searchParams.get('categories').split(',') : [],
      price: searchParams.get('price') ? searchParams.get('price') : '',
      sort: searchParams.get('sort') ? searchParams.get('sort') : '',
      page: searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1
    }
    setFilters(initialFilters)
  }, [searchParams])

  const fetchItemsData = (filters) => {
    if (['discount', 'package', 'clearence'].includes(entityType)) {
      fetchItemsByEntityType(entityType, true, filters)
    } else if (entityType === 'search') {
      fetchItemsByEntityType(null, null, filters)
    } else {
      if (entityData) {
        fetchItemsByEntityType(entityType, entityData.link, filters)
      } else {
        fetchItemsByEntityType(null, null, filters)
      }
    }
  }

  useEffect(() => {
    if (entityType) {
      const searchParamString = JSON.stringify(Object.fromEntries(searchParams.entries()))
      const currentParamsIdentifier = `${entityType}|${page}|${searchParamString}`
      if (!fetchAttempted || currentParamsIdentifier !== fetchAttempted) {
        if (prevEntityType.current !== entityType) {
          const defaultFilters = {
            brands: [],
            categories: [],
            price: '',
            sort: '',
            page: 1
          }
          setFilters(defaultFilters)
          setPage(1)
          setFetchAttempted(currentParamsIdentifier)
          fetchItemsData({ page: 1 })
          prevEntityType.current = entityType
        } else {
          const initialFilters = {
            brands: searchParams.get('brands') ? searchParams.get('brands').split(',') : [],
            categories: searchParams.get('categories') ? searchParams.get('categories').split(',') : [],
            price: searchParams.get('price') ? searchParams.get('price') : '',
            sort: searchParams.get('sort') ? searchParams.get('sort') : '',
            page: searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1
          }
          const searchParamFilters = { ...Object.fromEntries(searchParams.entries()) }
          setFetchAttempted(currentParamsIdentifier)
          setFilters(initialFilters)
          setPage(initialFilters.page)
          fetchItemsData(searchParamFilters)
        }
      }
    }
  }, [entityType, searchParams, entityData])

  const applyFilters = (filtersData) => {
    const brands = filtersData.brands.join(',')
    const categories = filtersData.categories.join(',')
    const price = filtersData.price
    const sort = filtersData.sort
    const updatedFilters = { ...Object.fromEntries(searchParams.entries()), brands, categories, price, sort, page: 1 }

    if (brands) {
      updatedFilters.brands = brands
    } else {
      delete updatedFilters.brands
    }

    if (categories) {
      updatedFilters.categories = categories
    } else {
      delete updatedFilters.categories
    }

    if (price) {
      updatedFilters.price = price
    } else {
      delete updatedFilters.price
    }

    if (sort) {
      updatedFilters.sort = sort
    } else {
      delete updatedFilters.sort
    }

    // fetchItemsData(updatedFilters)

    setSearchParams(updatedFilters)
  }

  const paginationHandleChange = (event, value) => {
    if (value !== page) {
      const updatedFilters = { ...Object.fromEntries(searchParams.entries()), page: parseInt(value, 10) }
      // fetchItemsData(updatedFilters)
      setSearchParams(updatedFilters)
      setPage(parseInt(value, 10))
    }
  }

  return (
    <Box sx={{ backgroundColor: theme.palette.secondary.main, padding: '40px 0' }}>
      <StyledBox>
        <Stack direction="row" spacing={{ xs: 0, sm: 1, md: 2 }} justifyContent="space-between">
          <Box sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            position: 'sticky',
            top: 150,
            zIndex: 10,
            maxHeight: '847px'
          }}
          >
              <Filter
              filtersData={filterProps}
              loading={state.items.loading}
              applyFilters={applyFilters}
              initialFilters={filters}
              // handleChange={handleFilterChange}
              />
          </Box>
          <Products
              items={state.items.results}
              loading={state.items.loading}
              totalPages={totalPages}
              pageNumber={page}
              onChangePagination={paginationHandleChange}
              filtersData={filterProps}
              applyFilters={applyFilters}
              initialFilters={filters}
              />
        </Stack>
      </StyledBox>
    </Box>
  )
}

export default Items
