import { jwtDecode } from 'jwt-decode'
import { logoutUserAction, showLoginModal, hideLoginModal } from '../actions/userActions'
import { favoritesReset } from '../actions/favoritesActions'
import { cartReset } from '../actions/cartActions'

const protectedActions = new Set([
  'UPDATE_CART',
  'ADD_TO_FAVORITES',
  'ATTEMPT_NAVIGATE_PROTECTED_ROUTE',
  'NAVIGATE_PUBLIC_ROUTE',
  'REMOVE_FROM_FAVORITES',
  'REMOVE_FROM_CART'
])

const authenticationMiddleware = (dispatch, getState) => (action) => {
  if (action._skipMiddleware) {
    dispatch(action)
    // return
  }

  const state = getState()
  const token = localStorage.getItem('token')
  if (token) {
    try {
      const userData = jwtDecode(token)
      const currentTime = Date.now() / 1000
      if (userData.exp <= currentTime) {
        localStorage.removeItem('token')
        dispatch(logoutUserAction())
        dispatch(favoritesReset())
        dispatch(cartReset())
        return
      }
    } catch {
      localStorage.removeItem('token')
      dispatch(logoutUserAction())
      dispatch(favoritesReset())
      dispatch(cartReset())
    }
  }

  if (protectedActions.has(action.type)) {
    if (!state.user.isAuthenticated && !state.user.loading) {
      dispatch(showLoginModal())
    }
  } else if (state.user.isAuthenticated && state.user.show) {
    dispatch(hideLoginModal())
  }
  if (action.type === 'NAVIGATE_PUBLIC_ROUTE') {
    dispatch(hideLoginModal())
  }

  dispatch({ ...action, _skipMiddleware: true })
}

export default authenticationMiddleware
