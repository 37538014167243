import {
  FAVORITES_FETCH, FAVORITES_LOADING, FAVORITES_FAILED,
  FAVORITES_RESET, ADD_TO_FAVORITES, REMOVE_FROM_FAVORITES
} from '../actions/favoritesActions'

export const initialState = { loading: false, results: [], error: null, total: 0 }

export const favoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FAVORITES_LOADING:
      return { ...state, loading: true }
    case FAVORITES_FETCH:
      return { ...state, loading: false, results: action.payload.data, total: action.payload.total }
    case FAVORITES_FAILED:
      return { ...state, loading: false, results: [], error: action.payload }
    case FAVORITES_RESET:
      return initialState
    case ADD_TO_FAVORITES: {
      const results = [...state.results, action.payload]
      const total = state.total += 1
      return { ...state, results, total }
    }
    case REMOVE_FROM_FAVORITES: {
      const results = action.payload
      const total = state.total -= 1
      return { ...state, results, total }
    }
    default:
      return state
  }
}
