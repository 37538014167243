import React, { useState } from 'react'
import { TextField, Button, Box, Stack } from '@mui/material'
import { theme } from '../../theme.js'
import { MuiTelInput } from 'mui-tel-input'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { useUser } from '../../hooks/useUser.js'
import { parseEmail } from '../../utils/validation'

const ProfileForm = ({ userData }) => {
  const { handleUpdateUserProfile } = useUser()

  const form = useForm({
    defaultValues: {
      cui: userData.cui,
      username: userData.username,
      email: userData.email,
      company: userData.company,
      phone: userData.phone || ''
    }
  })
  const { register, handleSubmit, watch, formState: { errors } } = form
  const [updateProfileMessage, setUpdateProfileMessage] = useState({ message: '', messageType: '' })
  const [loading, setLoading] = useState(false)

  const watchedFields = watch()

  const isFormUnchanged = Object.keys(watchedFields).every(
    key => watchedFields[key] === userData[key]
  )

  const onSubmit = async (data) => {
    setUpdateProfileMessage({ message: '', messageType: '' })
    setLoading(true) // Start loading
    try {
      const message = await handleUpdateUserProfile(data)
      setUpdateProfileMessage({ message, messageType: 'success' })
    } catch (error) {
      setUpdateProfileMessage({ message: 'Update profile failed: ' + error.message, messageType: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box sx={{ width: '100%', marginTop: '30px', padding: '20px', minHeight: { md: '50vh' } }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <TextField
            {...register('username')}
            label="Username"
            size='small'
            sx={{ bgcolor: theme.palette.primary.main, borderRadius: '5px' }}
            type="text"
            error={!!errors.username}
            helperText={errors.username?.message}
          />
          <TextField
            {...register('email', {
              required: 'Câmp obligatoriu',
              validate: value => parseEmail(value) === true || parseEmail(value)
            })}
            label="Email"
            size='small'
            sx={{ bgcolor: theme.palette.primary.main, borderRadius: '5px' }}
            type="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        <Controller
            name="phone"
            control={form.control} // Notice we're passing form.control here
            defaultValue={userData.phone} // Set the default value
            render={({ field }) => (
                <MuiTelInput
                {...field} // Spread the field object to pass value and onChange
                label="Telefon"
                size='small'
                defaultCountry='RO'
                onlyCountries={['RO']}
                sx={{ bgcolor: theme.palette.primary.main, borderRadius: '5px' }}
                InputLabelProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                inputProps={{ style: { fontSize: '14px', color: theme.palette.fontColor.main } }}
                />
            )}
        />
          <Button
            type="submit"
            variant="outlined"
            size='small'
            color="accent"
            sx={{ borderRadius: '21px', bgcolor: theme.palette.primary.main }}
            disabled={loading || isFormUnchanged}
          >
            Salvează
          </Button>
        </Stack>
      </form>
      {/* Display update profile message */}
      {updateProfileMessage.message && (
        <Box color={updateProfileMessage.messageType === 'success' ? 'green' : 'red'}>
          {updateProfileMessage.message}
        </Box>
      )}
    </Box>
  )
}

ProfileForm.propTypes = {
  userData: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    cui: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    company: PropTypes.string,
    username: PropTypes.string
  }).isRequired
}

export default ProfileForm
