import * as React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import { IconButton, Box } from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
// import { useItems } from '../../hooks/useItems'
import Filter from './Filter'
import PropTypes from 'prop-types'

export default function FilterDropdown ({ filtersData, initialFilters, loading, applyFilters }) {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  // const { fetchItemsByEntityType } = useItems()

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  //   function handleListKeyDown (event) {
  //     if (event.key === 'Tab') {
  //       event.preventDefault()
  //       setOpen(false)
  //     } else if (event.key === 'Escape') {
  //       setOpen(false)
  //     }
  //   }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <>
        <IconButton
          aria-label='filter-button'
          ref={anchorRef}
          id="filter-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <TuneIcon />
        </IconButton>
        <Popper

          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'right top' : 'right bottom'
              }}
            >
              <Paper variant='outlined' sx={{ width: '300px', maxHeight: 'calc(100vh - 64px)', p: '20px 0', overflowY: 'auto', scrollbarWidth: 'thin' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    <Filter filtersData={filtersData}
                            loading={loading}
                            applyFilters={applyFilters}
                            initialFilters={initialFilters}
                            closeFilter={setOpen}
                    />
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </>
  )
}

FilterDropdown.propTypes = {
  initialFilters: PropTypes.object,
  applyFilters: PropTypes.func,
  searchParams: PropTypes.object,
  filtersData: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    price: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.string),
    brands: PropTypes.arrayOf(PropTypes.string)
  })
}
