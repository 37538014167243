export const FAVORITES_FETCH = 'FAVORITES_FETCH'
export const FAVORITES_FAILED = 'FAVORITES_FAILED'
export const FAVORITES_LOADING = 'FAVORITES_LOADING'
export const FAVORITES_RESET = 'FAVORITES_RESET'
export const ADD_TO_FAVORITES = 'ADD_TO_FAVORITES'
export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES'

export const favoritesSuccessFetch = (results) => ({
  type: FAVORITES_FETCH,
  payload: results
})

export const favoritesFailedFetch = (error) => ({
  type: FAVORITES_FETCH,
  payload: error
})
export const favoritesLodingFetch = () => ({
  type: FAVORITES_LOADING
})
export const favoritesReset = () => ({
  type: FAVORITES_RESET
})

export const addToFavorites = (id) => ({
  type: ADD_TO_FAVORITES,
  payload: id
})

export const removeFromFavorites = (id) => ({
  type: REMOVE_FROM_FAVORITES,
  payload: id
})
