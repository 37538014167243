import { Box, Typography, MenuItem, styled } from '@mui/material'

export const StyledBox = styled(Box)({
  width: '100%',
  maxWidth: '1500px',
  margin: '0 auto'
})

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  justifyContent: 'center',
  padding: '12px 10px',
  fontWeight: 500,
  transition: 'color 0.3s ease',
  '&:hover': { color: '#e20613' }
}))

export const BrandName = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  fontWeight: 500,
  textTransform: 'uppercase',
  paddingBottom: '15px'
}))
export const ProductName = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '1.55',
  fontWeight: 500,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden'
}))

export const DiscountLabel = styled(Typography)(({ theme }) => ({
  position: 'relative',
  top: '10px', // Adjust as needed
  left: '10px', // Adjust as needed
  width: 'fit-content',
  backgroundColor: theme.palette.accent.main,
  color: theme.palette.primary.main,
  padding: '2px 10px',
  fontSize: '14px',
  fontWeight: 600,
  borderRadius: '4px'
}))
export const StockLabel = styled(Typography)(({ theme }) => ({
  position: 'relative',
  // bottom: '10px', // Adjust as needed
  left: '10px', // Adjust as needed
  width: 'fit-content',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.success.main,
  padding: '2px 10px',
  fontSize: '14px',
  fontWeight: 500,
  borderRadius: '4px'
}))
